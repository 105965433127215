import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Chakra imports
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Button,
  DrawerBody,
  Checkbox,
  IconButton,
  useColorModeValue,
  useDisclosure,
  Select,
  Input,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";

import InstanceMetricsParametersForm from "./InstanceMetricsParameterForm";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function BuildingTable(props) {
  const { map, columnsData, tableData, metricsContainer, setMetricsContainer, formInputs } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [selectedBuilding, setSelectedBuilding] = React.useState(null);

  const bgButton = useColorModeValue("secondaryGray.300", "gray.700");
  const brandText = useColorModeValue("brand.500", "white");

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleClick = (row) => {
    setSelectedBuilding(row);
    onOpen();
  };

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  return (
    <Card >
      <Flex
      >
        <Flex
          justify={{ base: "space-between" }}
          w="100%"
          px="30px"
          mb="20px"
        >
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="600"
            lineHeight="100%"
          >
            Buildings Selected
          </Text>
        </Flex>
      </Flex>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Project Instance Metrics Inputs
          </DrawerHeader>
          <DrawerBody>
            <InstanceMetricsParametersForm formInputs={formInputs} tableData={selectedBuilding} setTableData={data} metricsContainer={metricsContainer} setMetricsContainer={setMetricsContainer} onOpen={onClose}/>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Table overflow="auto" {...getTableProps()} variant="simple" color="gray.500">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="15px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr
                cursor="pointer"
                _hover={{ bg: "secondaryGray.100" }}
                onClick={() => {
                  map.fitBounds(row.original.clickToView, {duration: 0});
                }}
                {...row.getRowProps()}
                key={index}
                _checked={true}
              >


                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "Edit") {
                    data = (

                      <Flex align="center">
                        <IconButton
                          w="50px"
                          h="35px"
                          borderRadius="12px"
                          aria-label="Search database"
                          title="Delete all selected buildings"
                          bg={bgButton}
                          onClick={() => handleClick(row.original)}
                          icon={<EditIcon w="12px" h="12px" color={brandText} />}
                        />
                      </Flex>
                    );
                  }
                  else if (cell.column.Header === "Project Type") {
                    data = (

                      <Flex align="center">
                        <Text
                          color="secondaryGray.500"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          {row["original"].projectType}
                        </Text>
                      </Flex>
                    );
                  }
                  else if (cell.column.Header === "Building Name") {
                    data = (
                      <Flex align="center">
                        <Text>{row["original"].buildingName}</Text>
                      </Flex>
                    );
                  }
                  else if (cell.column.Header === "Building Id") {
                    data = (
                      <Flex align="center">
                        <Text
                          color="secondaryGray.500"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  }
                  else if (cell.column.Header === "Total Area (sq. ft)") {
                    data = (
                      <Flex align="center">
                        <Text
                          color="secondaryGray.500"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          {numberWithCommas(cell.value)}
                        </Text>
                      </Flex>
                    );
                  }
                  else if (cell.column.Header === "Total Usable Area (sq. ft)") {
                    data = (
                      <Flex align="center">
                        <Text
                          color="secondaryGray.500"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          {numberWithCommas(cell.value)}
                        </Text>
                      </Flex>
                    );
                  }
                  else if (cell.column.Header === "Number of Obstructions") {
                    data = (
                      <Flex align="center">
                        <Text
                          color="secondaryGray.500"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "120px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                      mt="20px !important"
                      py="10px !important"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}

export default BuildingTable;
