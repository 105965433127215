import { ImPowerCord } from "react-icons/im";
import { RiPercentLine } from "react-icons/ri";
import { GiSolarPower } from "react-icons/gi";
import { FaSolarPanel } from "react-icons/fa";
import { SiLinuxcontainers } from "react-icons/si";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { BsPersonBadgeFill } from "react-icons/bs";
import { GrMapLocation } from "react-icons/gr";
import { BiCurrentLocation } from "react-icons/bi";
import structuredClone from '@ungap/structured-clone';

export default class MetricsContainer {
  static copyMetricsContainer(metricsContainer) {
    const copyiedContainer = new MetricsContainer();
    copyiedContainer.summary = structuredClone(metricsContainer.summary);
    copyiedContainer.engineering = structuredClone(metricsContainer.engineering);
    copyiedContainer.cost = { ...metricsContainer.cost };
    copyiedContainer.financial = { ...metricsContainer.financial };
    copyiedContainer.environmental = { ...metricsContainer.environmental };
    return copyiedContainer;
  }
  constructor() {
    this.metricsLabels = {
      projectName: {
        label: "Project Name",
        unit: "",
        icon: MdDriveFileRenameOutline,
        description: "Project Name",
      },
      projectId: {
        label: "Project ID",
        unit: "",
        icon: BsPersonBadgeFill,
        description: "Project ID",
      },
      projectAddress: {
        label: "Project Address",
        unit: "",
        icon: GrMapLocation,
        description: "Project Address",
      },
      projectCoordinates: {
        label: "Project Coordinates",
        unit: "",
        icon: BiCurrentLocation,
        description: "Project Coordinates",
      },
      dcSize: {
        label: "DC Size",
        unit: "kW",
        icon: ImPowerCord,
        description: "The total DC size of the PV system",
      },
      acSize: {
        label: "AC Size",
        unit: "kW",
        icon: ImPowerCord,
        description: "The total AC size of the PV system",
      },
      dcacRatio: {
        label: "DC to AC Ratio",
        unit: "%",
        icon: RiPercentLine,
        description: "The ratio of DC to AC size of the PV system",
      },
      specificProduction: {
        label: "Specific Production",
        unit: "kWh/kWp",
        icon: GiSolarPower,
        description: "The specific production/yield of the PV system",
      },
      moduleCount: {
        label: "Module Count",
        unit: "Num Modules",
        icon: FaSolarPanel,
        description: "The number of modules in the PV system",
      },
      optimzerCount: {
        label: "Optimizer Count",
        unit: "Num Optimizers",
        icon: SiLinuxcontainers,
        description: "The number of optimizers in the PV system",
      },
      annualProduction: {
        label: "Annual Production",
        unit: "kWh",
        icon: GiSolarPower,
        description: "The annual production of the PV system",
      },
      capacityFactor: {
        label: "Capacity Factor",
        unit: "%",
        icon: RiPercentLine,
        description: "The capacity factor of the PV system",
      },
      constructionCostDollar: {
        label: "Construction Cost",
        unit: "$",
        icon: GiSolarPower,
        description: "The construction cost of the PV system",
      },
      constructionCostWatt: {
        label: "Construction Cost per Watt",
        unit: "$/W",
        icon: GiSolarPower,
        description: "The construction cost per watt of the PV system",
      },
      contractPriceDollar: {
        label: "Contract Price",
        unit: "$",
        icon: GiSolarPower,
        description: "The contract price of the PV system",
      },
      contractPriceWatt: {
        label: "Contract Price per Watt",
        unit: "$/W",
        icon: GiSolarPower,
        description: "The contract price per watt of the PV system",
      },
      irr: {
        label: "IRR",
        unit: "%",
        icon: RiPercentLine,
        description: "The internal rate of return of the PV system",
      },
      ppa: {
        label: "PPA",
        unit: "$/kWh",
        icon: GiSolarPower,
        description: "The power purchase agreement of the PV system",
      },
      paybackPeriod: {
        label: "Payback Period",
        unit: "Years",
        icon: RiPercentLine,
        description: "The payback period of the PV system",
      },
      tonsOfCO2: {
        label: "Tons of CO2",
        unit: "Tons",
        icon: GiSolarPower,
        description: "The tons of CO2 avoided by the PV system",
      },
      barrelsOfOil: {
        label: "Barrels of Oil",
        unit: "Barrels",
        icon: GiSolarPower,
        description: "The barrels of oil avoided by the PV system",
      },
      gasConsumed: {
        label: "Gas Consumed",
        unit: "Gallons",
        icon: GiSolarPower,
        description: "The gas consumed by the PV system",
      }
    };
    // Set deafult state
    this.summary = {
      projectName: "",
      projectId: "",
      projectAddress: "",
      projectCoordinates: [0, 0],
    };

    this.engineering = {
      dcSize: "--",
      acSize: "--",
      specificProduction: "--",
      dcacRatio: "--",
      moduleCount: "--",
      optimzerCount: "--",
      annualProduction: "--",
      capacityFactor: "--",
      buildings: {},
    };

    this.cost = {
      constructionCostWatt: "--",
      constructionCostDollar: "--",
      contractPriceDollar: "--",
      contractPriceWatt: "--",
      buildings: {},
    };

    this.financial = {
      irr: "--",
      ppa: "--",
      npv: "--",
      buildings: {},
    };

    this.environmental = {
      tonsOfCO2: "--",
      gasConsumed: "--",
      barrelsOfOil: "--",
      smartPhonesCharged: "--",
      buildings: {},
    };
  }

  getProjectSummary() {
    var stats = {};

    var values = Object.keys(this.summary);

    for (const value of values) {
      if (this.metricsLabels[value] !== undefined) {
        stats[value] = {
          tag: "summary",
          label: this.metricsLabels[value].label,
          target: this.summary[value],
          unit: this.metricsLabels[value].unit,
          icon: this.metricsLabels[value].icon,
          description: this.metricsLabels[value].description,
        };
        // If stats value is a list of coordinates, convert to string
        if (Array.isArray(stats[value].target)) {
          stats[value].target = stats[value].target.join(", ");
        }
      }
    }

    var engineering_values = [];

    engineering_values.push("dcSize");
    engineering_values.push("acSize");
    engineering_values.push("specificProduction");
    engineering_values.push("annualProduction");

    for (const value of engineering_values) {
      if (this.metricsLabels[value] !== undefined) {
        stats[value] = {
          tag: "engineering",
          label: this.metricsLabels[value].label,
          target: this.engineering[value]
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          unit: this.metricsLabels[value].unit,
          icon: this.metricsLabels[value].icon,
          description: this.metricsLabels[value].description,
        };
        // If stats value is a list of coordinates, convert to string
        if (Array.isArray(stats[value].target)) {
          stats[value].target = stats[value].target.join(", ");
        }
      }
    }

    var cost_values = [];

    cost_values.push("constructionCostWatt");
    cost_values.push("constructionCostDollar");
    cost_values.push("contractPriceDollar");
    cost_values.push("contractPriceWatt");

    for (const value of cost_values) {
      if (this.metricsLabels[value] !== undefined) {
        stats[value] = {
          tag: "cost",
          label: this.metricsLabels[value].label,
          target: this.cost[value]
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          unit: this.metricsLabels[value].unit,
          icon: this.metricsLabels[value].icon,
          description: this.metricsLabels[value].description,
        };
        // If stats value is a list of coordinates, convert to string
        if (Array.isArray(stats[value].target)) {
          stats[value].target = stats[value].target.join(", ");
        }
      }
    }

    var finance_values = [];

    finance_values.push("irr");
    finance_values.push("ppa");

    for (const value of finance_values) {
      if (this.metricsLabels[value] !== undefined) {
        stats[value] = {
          tag: "finance",
          label: this.metricsLabels[value].label,
          target: this.financial[value],
          unit: this.metricsLabels[value].unit,
          icon: this.metricsLabels[value].icon,
          description: this.metricsLabels[value].description,
        };
        // If stats value is a list of coordinates, convert to string
        if (Array.isArray(stats[value].target)) {
          stats[value].target = stats[value].target.join(", ");
        }
      }
    }
    
    // Need to deal with payback period in a special way
    finance_values.push("paybackPeriod");
    console.log(this.financial)
    if (this.metricsLabels["paybackPeriod"] !== undefined) {
      stats["paybackPeriod"] = {
        tag: "finance",
        label: this.metricsLabels["paybackPeriod"].label,
        // Unpack the payback period dict [years, months, building id]
        target: "Years: " + this.financial["paybackPeriod"][0]["Years"] + " Months: " + this.financial["paybackPeriod"][0]["Months"],
        unit: this.metricsLabels["paybackPeriod"].unit,
        icon: this.metricsLabels["paybackPeriod"].icon,
        description: this.metricsLabels["paybackPeriod"].description,
      };
  }




    var environmental_values = [];

    environmental_values.push("tonsOfCO2");
    environmental_values.push("gasConsumed");
    environmental_values.push("barrelsOfOil");

    for (const value of environmental_values) {
      if (this.metricsLabels[value] !== undefined) {
        stats[value] = {
          tag: "environmental",
          label: this.metricsLabels[value].label,
          target: this.environmental[value],
          unit: this.metricsLabels[value].unit,
          icon: this.metricsLabels[value].icon,
          description: this.metricsLabels[value].description,
        };
        // If stats value is a list of coordinates, convert to string
        if (Array.isArray(stats[value].target)) {
          stats[value].target = stats[value].target.join(", ");
        }
      }
    }

    return stats;
  }

  getStats(section, id) {
    if (id === "acrossAll") {
      // Filter out the buildings key
      var stats = {};
      const values = Object.keys(this[section]).filter(
        (key) => key !== "buildings"
      );

      for (const value of values) {
        if (this.metricsLabels[value] !== undefined) {
          stats[value] = {
            label: this.metricsLabels[value].label,
            target: this[section][value]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            unit: this.metricsLabels[value].unit,
            icon: this.metricsLabels[value].icon,
            description: this.metricsLabels[value].description,
          };
        }
      }

      return stats;
    } else {
      var stats = {};
      const values = Object.keys(this[section].buildings[id].outputs);
      for (const value of values) {
        if (this.metricsLabels[value] !== undefined) {
          stats[value] = {
            label: this.metricsLabels[value].label,
            target: this[section][value]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            unit: this.metricsLabels[value].unit,
            icon: this.metricsLabels[value].icon,
            description: this.metricsLabels[value].description,
          };
        }
      }

      return stats;
    }
  }

  addBuilding(buildingMetric, projectDefaultParams) {
    console.log(projectDefaultParams);
    if (this.engineering.buildings[buildingMetric.buildingId] !== undefined) {
      // Only update the building if it already exists
      this.engineering.buildings[buildingMetric.buildingId].inputs =
        buildingMetric;
      return;
    }
    this.engineering.buildings[buildingMetric.buildingId] = {
      inputs: { ...buildingMetric },
      selectables: { ...projectDefaultParams.engineering },
      outputs: {},
    };

    this.cost.buildings[buildingMetric.buildingId] = {
      inputs: {},
      selectables: { ...projectDefaultParams.cost },
      outputs: {},
    };

    this.financial.buildings[buildingMetric.buildingId] = {
      inputs: {},
      selectables: { ...projectDefaultParams.financial },
      outputs: {},
    };
    this.environmental.buildings[buildingMetric.buildingId] = {
      inputs: {},
      selectables: {},
      outputs: {},
    };
  }

  removeBuilding(buildingId) {
    delete this.engineering.buildings[buildingId];
    delete this.cost.buildings[buildingId];
    delete this.financial.buildings[buildingId];
    delete this.environmental.buildings[buildingId];
  }
}
