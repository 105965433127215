// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import illustration from "assets/img/auth/sa.jpg";
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/types/Default";
import { NavLink, Redirect } from "react-router-dom";
import React from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import { useStytchSession, useStytch } from "@stytch/react";

import MicrosoftOAuth from "components/authentification/MicrosoftOAuth";

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("gray.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const invalidInputColor = useColorModeValue("brand.500", "red");

  // Stytch state
  const stytchClient = useStytch();
  const session = useStytchSession();

  const [show, setShow] = React.useState(false);

  // Email, Password, Visibility
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [invalidfirstName, setInvalidfirstName] = React.useState(false);
  const [invalidlastName, setInvalidlastName] = React.useState(false);
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] =
    React.useState(false);
  const [invalidPassword, setInvalidPassword] = React.useState(false);

  // Check if session is valid
  if (session.session) {
    return <Redirect to="/app" />;
  }

  const handleClick = async (e) => {
    e.preventDefault();

    // Check if first name is empty
    if (firstName === "") {
      setInvalidfirstName(true);
      setInvalidlastName(false);
      setInvalidEmail(false);
      setInvalidPassword(false);
      setEmailAlreadyRegistered(false);
      return;
    }

    // Check if last name is empty
    if (lastName === "") {
      setInvalidfirstName(false);
      setInvalidlastName(true);
      setInvalidEmail(false);
      setInvalidPassword(false);
      setEmailAlreadyRegistered(false);
      return;
    }

    // Create stytch user
    stytchClient.passwords
      .create({
        email: email,
        password: password,
        session_duration_minutes: 60,
      })
      .then((response) => {

        stytchClient.user.update({
          name: {
            first_name: firstName,
            last_name: lastName,
          },
        });

        const stytch_user_id = response.user_id;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json",
          },
          body: JSON.stringify({
            user_id: stytch_user_id,
            firstName: firstName,
            lastName: lastName,
            email: email,
          }),
        };

        fetch(
          "http://localhost:8000/ape/api/v1/users/" + stytch_user_id,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
          });
      })
      .catch((error) => {
        if (error.message.includes("invalid_email")) {
          setInvalidfirstName(false);
          setInvalidlastName(false);
          setInvalidEmail(true);
          setInvalidPassword(false);
          setEmailAlreadyRegistered(false);
        }
        if (error.message.includes("duplicate_email")) {
          setInvalidfirstName(false);
          setInvalidlastName(false);
          setInvalidEmail(false);
          setInvalidPassword(false);
          setEmailAlreadyRegistered(true);
        }
        if (error.message.includes("weak_password")) {
          setInvalidfirstName(false);
          setInvalidlastName(false);
          setInvalidEmail(false);
          setInvalidPassword(true);
          setEmailAlreadyRegistered(false);
        }
      });
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "8vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            color={textColor}
            fontSize={{ base: "34px", lg: "36px" }}
            mb="10px"
          >
            Sign Up
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign up!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <MicrosoftOAuth />
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color={textColorSecondary} mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <SimpleGrid
              columns={{ base: "1", md: "2" }}
              gap={{ sm: "10px", md: "26px" }}
            >
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  First Name<Text color={brandStars}>*&nbsp;</Text>
                  <Text
                    color={invalidInputColor}
                    fontWeight="400"
                    fontSize="sm"
                  >
                    {invalidfirstName ? "Input Required!" : null}
                  </Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  fontSize="sm"
                  ms={{ base: "0px", md: "4px" }}
                  placeholder="First name"
                  variant="auth"
                  onChange={(event) => setFirstName(event.target.value)}
                  mb="24px"
                  size="lg"
                />
              </Flex>
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  First Name<Text color={brandStars}>*&nbsp;</Text>
                  <Text
                    color={invalidInputColor}
                    fontWeight="400"
                    fontSize="sm"
                  >
                    {invalidlastName ? "Input Required!" : null}
                  </Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  placeholder="Last name"
                  mb="24px"
                  onChange={(event) => setLastName(event.target.value)}
                  size="lg"
                />
              </Flex>
            </SimpleGrid>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*&nbsp;</Text>
              <Text color={invalidInputColor} fontWeight="400" fontSize="sm">
                {invalidEmail ? "Invalid Email!" : null}
                {emailAlreadyRegistered ? "Email already registered!" : null}
              </Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              type="email"
              placeholder="Email"
              mb="24px"
              onChange={(event) => setEmail(event.target.value)}
              size="lg"
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              isRequired={true}
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*&nbsp;</Text>
              <Text color={invalidInputColor} fontWeight="400" fontSize="sm">
                {invalidPassword ? "Invalid Password!" : null}
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "4px" }}
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                onChange={(event) => setPassword(event.target.value)}
                type={show ? "text" : "password"}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            <Button
              variant="brand"
              fontSize="14px"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleClick}
            >
              Create my account
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="sm">
              Already a member?
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
