export const tableBuildingColumns = [
  {
    Header: "Edit",
    accessor: "edit",
  },
  {
    Header: "Project Type",
    accessor: "projectType",
  },
  {
    Header: "Building Name",
    accessor: "buildingName",
  },
  {
    Header: "Building Id",
    accessor: "buildingId",
  },
  {
    Header: "Total Area (sq. ft)",
    accessor: "totalArea",
  },
  {
    Header: "Total Usable Area (sq. ft)",
    accessor: "totalUsableArea",
  },
  {
    Header: "Number of Obstructions",
    accessor: "numberOfObstructions",
  },
];
