import React from "react";

// Chakra imports
import Card from "components/card/Card";

// Mapbox imports
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import * as mapConfigs from "./config/mapComponentConfigs.js";
import { buildBuildingSelectionTool } from "./map_helpers/buildBuildingSelectionTool.js";
import { buildingBuildingObstructionDetectorTools } from "./map_helpers/buildBuildingObstructionDetectorTool.js";
import { setMapDrawUpdates } from "./map_helpers/setMapDrawUpdates.js";
import MapboxGLButtonControl from "./map_helpers/MapBoxGlButtonControl.js";
import { createBuilding } from "./map_helpers/BuildingContainer.js";
import { useStytchUser } from "@stytch/react";
import turf from "turf/turf";

import "assets/css/Map.css";
import { useColorModeValue } from "@chakra-ui/system";

import { Spinner } from "@chakra-ui/react";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA";

export default function MapComponent(props) {
  const {
    map,
    setMap,
    metricsContianer,
    setMetricsContainer,
    buildingTableData,
    setBuildingTableData,
    projectId,
    initalBuildingTable,
    projectDefaultParams,
    rest,
  } = props;

  const outlineColor = useColorModeValue("black", "white");

  const mapContainerRef = React.useRef(null);
  const selectedBuildings = {};
  const stytchUser = useStytchUser();

  const [loadingData, setLoadingData] = React.useState(true);

  React.useEffect(() => {
    const mapConfig = mapConfigs.buildMapBoxConfig(
      mapContainerRef.current,
      "mapbox://styles/aymans/cl5ap72lp000c16msbfcho9bs",
      metricsContianer.summary.projectCoordinates
    );

    const map = new mapboxgl.Map(mapConfig);
    setMap(map);
    map.doubleClickZoom.disable();

    map.on("load", () => {
      map.addSource("buildings", mapConfigs.mapBoxVectorLayer);
      map.addLayer({
        id: "buildings",
        type: "fill",
        source: "composite",
        "source-layer": "building",
        type: "line",
        paint: {
          "line-color": "#a66f00",
          "line-opacity": 0.75,
        },
        // Display none by adding a
        // filter with an empty string.
        filter: ["in", "$id", ""],
      });
    });
    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      })
    );
    // Build Building Selection Control
    const draw = buildBuildingSelectionTool(
      map,
      selectedBuildings,
      setBuildingTableData,
      metricsContianer,
      setMetricsContainer,
      projectDefaultParams
    );

    // Build process buildings button
    function processBuildingsButton(e) {
      buildingBuildingObstructionDetectorTools(
        e,
        map,
        selectedBuildings,
        projectId,
        stytchUser.user.user_id,
        draw,
        metricsContianer,
        setMetricsContainer,
        setBuildingTableData,
        projectDefaultParams
      );
    }
    const drawBar = new MapboxGLButtonControl({
      draw: draw,
      buttons: [
        {
          on: "click",
          action: () => {
            draw.changeMode("buildingSelect", { count: 7 });
          },
          classes: ["mapboxgl-building-selector"],
        },
        {
          on: "click",
          action: processBuildingsButton,
          classes: ["mapboxgl-process-building"],
        },
      ],
    });
    map.on("render", () => {
      map.resize();
    });
    map.addControl(drawBar, "top-left");

    // Map draw updates
    setMapDrawUpdates(
      map,
      selectedBuildings,
      draw,
      setBuildingTableData,
      metricsContianer,
      setMetricsContainer,
      projectDefaultParams
    );
    // Iterate a draw all buildings and obstructions in buildingTableData

    // wait 10 seconds for the map to load, while waiting for the map to load, make the map invisible

    setTimeout(() => {
      initalBuildingTable.forEach((building) => {
        draw.add(building.buildingPolygon);
        building.buildingObstructions.forEach((obstruction) => {
          draw.add(obstruction);
        });

        createBuilding(
          map,
          building.buildingPolygon,
          building.buildingObstructions,
          selectedBuildings,
          setBuildingTableData,
          metricsContianer,
          setMetricsContainer,
          projectDefaultParams
        );

        map.fitBounds(turf.bbox(building.buildingPolygon), { duration: 0 });
      });
    }, 5000);

    var counter = 0;

    // Get total number of obstructions
    var totalObstructions = 0;
    initalBuildingTable.forEach((building) => {
      totalObstructions += building.buildingObstructions.length - 1;
    });

    totalObstructions = 20;

    console.log(totalObstructions);

    map.on("sourcedata", (e) => {
      // Check if setback string is in the source ID
      console.log(e);
      if (loadingData === false) {
        return;
      }
      if (
        e.sourceId.includes("setback") ||
        e.sourceId.includes("mapbox-gl-draw")
      ) {
        counter++;
        console.log(counter);
        if (counter >= totalObstructions) {
          const spinnerEl = document.getElementById("spinner");
          const backgroundEl = document.getElementById("loading-background");
          const addLayerBtn = document.getElementById("addlayer");
          spinnerEl.classList.remove("loading");
          backgroundEl.classList.remove("absolute");
          backgroundEl.classList.add("none");
        }
      }
    });

    return () => map.remove();
  }, [loadingData]);

  return (
    <Card cursor="pointer" h="1000px" align="center" outline={outlineColor}>
      {/*Create opqauq loading screen if loading is true */}
      <div align="center" className="map-container" ref={mapContainerRef} />

      <div
        id="loading-background"
        class="flex-parent flex-parent--center-cross flex-parent--center-main absolute top right bottom left bg-darken10 z5"
      >
        <div id="spinner" class="flex-child loading"></div>
      </div>
    </Card>
  );
}
