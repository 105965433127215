import React from "react";
// Mapbox imports
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as mapConfigs from "./config/mapComponentConfigs.js";
import Card from "components/card/Card.js";
import ReactDOM from "react-dom"
import NewProjectForm from "components/popup/NewProjectForm.js";
import turf from "turf/turf";
import {
  Button,
  Text,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
export default function ProjectsMapView(props) {
  const { map, setMap, tableData } = props;

  const popUpRef = React.useRef(new mapboxgl.Popup({ offset: 15 }))
  const textColor = useColorModeValue("secondaryGray.900", "black");
  const mapContainerRef = React.useRef(null);
  const [coordinateSelected, setCoordinateSelected] = React.useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useLayoutEffect = React.useEffect;
  React.useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/aymans/cl5ap72lp000c16msbfcho9bs",
    });
    setMap(map);
    map.doubleClickZoom.disable();
    map.on("load", () => {
      map.addSource("buildings", mapConfigs.mapBoxVectorLayer);
      map.addLayer({
        id: "buildings",
        type: "fill",
        source: "composite",
        "source-layer": "building",
        type: "line",
        paint: {
          "line-color": "#a0a0a0",
          "line-opacity": 0.75,
        },
        // Display none by adding a
        // filter with an empty string.
        filter: ["in", "$id", ""],
      });
    });
    map.on("click", function (e) {
      console.log(e);
      // Check if the click event is on a feature in the "buildings" layer.
      var features = map.queryRenderedFeatures(e.point, {
        filter: ["==", "extrude", "true"],
        validate: true,
      });
      if (features.length) {
        const selectedFeature = features.reduce(
          (max, f) => (turf.area(f) > turf.area(max) ? f : max),
          features[0]
        );

        // If feature id is undefined return
        if (selectedFeature.id === undefined) {
          return;
        }

        // Filter an additional layer of features to only include buildings
        // Sometimes mapbox will return sub features of buildings, this is due
        // to the way mapbox renders buildings (Tilequery), there fore we must
        // merge all features with the same id into one feature
        const allFeaturesWithSameId = map.querySourceFeatures("composite", {
          sourceLayer: "building",
          filter: ["==", "$id", selectedFeature.id],
        });

        // Create union of all features with the same id
        // Union can only merge two features at a time
        const mergedFeatures = allFeaturesWithSameId.reduce((a, b) =>
          turf.union(a, b)
        );
        var feature = features[0];
        // Get lat and long of the feature
        setCoordinateSelected(mergedFeatures);

        const popupNode = document.createElement("div");
        ReactDOM.render(
          <Button onClick={onOpen}>
            <Text color={textColor}>Create Project</Text></Button>,
          popupNode
        );
        popUpRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map);
      }

      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = features.length ? "pointer" : "";

      // Set a filter to show features that match the clicked feature's id.
      map.setFilter("buildings", [
        "in",
        "$id",
        features.length ? features[0].id : "",
      ]);

      // Set a filter to show features that match the clicked feature's id.
      map.setFilter("buildings", [
        "in",
        "$id",
        features.length ? features[0].id : "",
      ]);
    });

    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      })
    );

    tableData.forEach((project) => {
      const marker = new mapboxgl.Marker()
        .setLngLat([
          project.projectCoordinates[0],
          project.projectCoordinates[1],
        ])
        .addTo(map);
    });

    map.on("render", () => {
      map.resize();
    });

    return () => map.remove();
  }, []);

  return (
    <Card cursor="pointer" align="center" maxH="100vh">
      <NewProjectForm
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                projectCoordinates={coordinateSelected}
      />
      <div
        align="center"
        className="map-container-projects"
        ref={mapContainerRef}
      />
    </Card>
  );
}
