import React from "react";
import { Box, Flex, Stack, useBreakpointValue } from "@chakra-ui/react";
import { useStytchUser } from "@stytch/react";

import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "safariRoutes.js";

export default function Safari() {
  const [selectedPage, setSelectedPage] = React.useState("Home");
  const stytchUser = useStytchUser();

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/safari") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  return (
    <Flex
      as="section"
      direction={{
        base: "row",
        lg: "row",
      }}
      maxH="100vh"
      bg="bg-canvas"
      scroll="hidden"
      /*Enable 0 scrolling */
    >
      {isDesktop ? <Sidebar routes={routes} /> : <Navbar routes={routes} />}
      <Box
        bg="bg-surface"
        pt={{
          base: "0",
          lg: "3",
        }}
        flex="1"
        maxH="100vh"
      >
        
          <Flex as="section" maxH="100vh">
            <Flex
              flex="1"
              bg="bg-surface"
              py={{
                base: "6",
                sm: "8",
              }}
              px={{
                base: "4",
                sm: "6",
              }}
              height="10%"
            >
              {/*Set height to a screen size vertically */}
              
              <Stack maxH="100vh" width="full">
                <Box
                  mx="auto"
                  w="100%"
                  p={{ base: "20px", md: "30px" }}
                  pe="20px"
                  pt="50px"
                  maxH="100vh"
                >
                  <Switch>{getRoutes(routes)}</Switch>
                </Box>
              </Stack>
            </Flex>
          </Flex>
        </Box>
    </Flex>
  );
}
