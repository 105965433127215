import React from "react";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import useInput from "./MapboxInput";
import { useForm } from "react-hook-form";
import turf from "turf/turf";
import { Point } from "mapbox-gl";

import Card from "components/card/Card";
import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  FormHelperText,
  Radio,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Box,
  Select,
} from "@chakra-ui/react";

import InputField from "components/fields/InputField";

import { useStytchUser } from "@stytch/react";
import { project } from "@deck.gl/core";

function NewProjectForm(props) {
  const { isOpen, onOpen, onClose, projectCoordinates, map } = { ...props };
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgButton = useColorModeValue("secondaryGray", "white");
  const bgText = useColorModeValue("white", "secondaryGray.900");
  const geocoderContainerRef = React.createRef();

  const address = useInput("");

  const [projectName, setProjectName] = React.useState("");
  const [runMl, setRunMl] = React.useState(false);
  const stytchUser = useStytchUser();
  const [result, setResult] = React.useState([]);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const [waitingForAPI, setWaitingForAPI] = React.useState(false);

  const SuggestionWrapper = styled.div`
    width: 400px;
    padding: 10px 20px;
    border-radius: 0px 0px 10px 10px;
  `;

  const Suggestion = styled.p`
    cursor: pointer;
    bordercolor: #e2e8f0;
    max-width: 400px;
  `;

  const handleCheckboxChange = (e) => {
    setRunMl(e.target.checked);
  };

  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiYXltYW5zIiwiYSI6ImNrdzViYTVjMTBuaGIzMG8wdW02b2dqZGMifQ.iopJkQ7QLqyBXTcgeALv-Q";

  async function onSubmit(values) {
    setWaitingForAPI(true);
    const projectId = uuidv4();
    var mergedFeatures = undefined;
    if (projectCoordinates) {
      // copy object
      mergedFeatures = JSON.parse(JSON.stringify(projectCoordinates));
      values["mergedFeature"] = mergedFeatures;
      // Get center of project
      const center = turf.center(mergedFeatures);
      values["projectCoordinates"] = [
        center.geometry.coordinates[1],
        center.geometry.coordinates[0],
      ];
    } else {
      values["projectCoordinates"] = [];

      const response = await fetch("/ape/api/v1/project/getCoordinates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          address: values.projectAddress,
        }),
      });

      const data = await response.json();
      console.log(data);
      map.jumpTo({
        center: [data.lng, data.lat],
        zoom: 23,
      });

      // Wait for map to load
      await new Promise((r) => setTimeout(r, 1000));

      values.projectCoordinates = [];

      // Get size of the map
      var mapbox_container =
        document.getElementsByClassName("mapboxgl-canvas")[0];
      var mapbox_container_width = mapbox_container.width;
      var mapbox_container_height = mapbox_container.height;
      var event = new Point(
        mapbox_container_width / 2,
        mapbox_container_height / 2
      );

      const features = map.queryRenderedFeatures(event, {
        filter: ["==", "extrude", "true"],
        validate: true,
      });

      console.log(features);

      if (features.length > 0) {
        // Reduce all features to the one with the greatest area
        const selectedFeature = features.reduce(
          (max, f) => (turf.area(f) > turf.area(max) ? f : max),
          features[0]
        );

        // If feature id is undefined return
        if (selectedFeature.id === undefined) {
          return;
        }

        // Filter an additional layer of features to only include buildings
        // Sometimes mapbox will return sub features of buildings, this is due
        // to the way mapbox renders buildings (Tilequery), there fore we must
        // merge all features with the same id into one feature
        const allFeaturesWithSameId = map.querySourceFeatures("composite", {
          sourceLayer: "building",
          filter: ["==", "$id", selectedFeature.id],
        });

        // Create union of all features with the same id
        // Union can only merge two features at a time
        mergedFeatures = allFeaturesWithSameId.reduce((a, b) =>
          turf.union(a, b)
        );
      }
    }
    if (values["projectAddress"] == undefined) {
      values["projectAddress"] = "";
    }

    const newProjectPayload = {
      projectId: projectId,
      projectName: values.projectName,
      projectClient: values.projectClient,
      projectAddress: values.projectAddress,
      projectCoordinates: values.projectCoordinates,
      mergedFeatures: mergedFeatures,
      projectMLOptions: {
        processViaML: values.runMl,
      },
    };

    console.log(newProjectPayload);

    fetch("/ape/api/v1/project/create/" + stytchUser.user.user_id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(newProjectPayload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Route to project page
        window.location.href = "/safari-ape/#/safari/project/:" + projectId;
        setWaitingForAPI(false);
      });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Flex w={{ sm: "330px", md: "700px", lg: "850px" }}>
          <Card
            /*Create rounded edges*/
            borderRadius="10px"
          >
            <form w="100%" onSubmit={handleSubmit(onSubmit)}>
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                New Project
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <FormControl id="projectName" isRequired>
                    <Heading size="md">New Project Form</Heading>
                    <FormLabel>Project Name</FormLabel>
                    <Input
                      color={textColor}
                      {...register("projectName", { required: false })}
                    />
                    <FormHelperText>Name of the project.</FormHelperText>
                    <FormLabel>Project Client</FormLabel>
                    <Input
                      color={textColor}
                      {...register("projectClient", { required: false })}
                    />
                    <FormHelperText>
                      Client/Stakeholder of the project.
                    </FormHelperText>
                    {projectCoordinates == undefined && (
                      <div>
                        <FormLabel>Project Address</FormLabel>
                        <Input
                          mb="0px"
                          id="last"
                          textColor={textColor}
                          placeholder="eg. 1407 Broadway St New York, NY 10018"
                          {...register("projectAddress", { required: false })}
                          {...address}
                        />
                        <SuggestionWrapper cursor="pointer">
                          {address.suggestions.map((suggestion, index) => {
                            return (
                              <Suggestion
                                borderColor="gray.200"
                                borderWidth="1px"
                                cursor="pointer"
                                key={index}
                                onClick={() => {
                                  address.setValue(suggestion.place_name);
                                  address.setSuggestions([]);
                                }}
                              >
                                {suggestion.place_name}
                              </Suggestion>
                            );
                          })}
                        </SuggestionWrapper>
                      </div>
                    )}
                  </FormControl>

                  <FormControl id="runMl">
                    <FormLabel>Project ML Settings</FormLabel>
                    <Checkbox
                      borderColor={"linkedin.500"}
                      size={"lg"}
                      colorScheme={"linkedin"}
                      {...register("runMl", { required: false })}
                    >
                      <Text>Process Automatically</Text>
                    </Checkbox>
                  </FormControl>
                </Stack>
                <Flex justify="space-between" mt="24px">
                  <Button
                    color={bgButton}
                    fontSize="sm"
                    borderRadius="16px"
                    borderColor={bgButton}
                    w={{ base: "128px", md: "148px" }}
                    h="46px"
                    ms="auto"
                    isLoading={waitingForAPI}
                    type="submit"
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </form>
          </Card>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default NewProjectForm;
