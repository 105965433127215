import React, { useState, useEffect } from "react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Flex,
  Icon,
  Text,
  Box,
  Button,
  useColorModeValue,
  SimpleGrid,
  TabPanels,
  Select,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useCallback } from "react";
import { useRef } from "react";
import { Stat } from "./Stat";
import {
  MdOutlineSummarize,
  MdEngineering,
  MdConstruction,
  MdMoney,
  MdEdit,
  MdBatteryChargingFull,
} from "react-icons/md";
import MetricsContainer from "./objects/MetricsContainer";
import { ImPowerCord } from "react-icons/im";
var _ = require("lodash");

export default function ResultsTab(props) {
  const { userId, projectId, metricsContainer, setMetricsContainer } = props;

  useEffect(() => {
    setTimeout(() => {
      console.log("app is ready");
    }, 2000);
  }, []);

  useEffect(() => {
    console.log(metricsContainer);
  }, [metricsContainer]);

  let [tabState, setTabState] = React.useState("Project Summary");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("white", "secondaryGray.900");
  const [selectedBuilding, setSelectedBuilding] = useState("acrossAll");

  const [waitingForAPI, setWaitingForAPI] = useState(false);

  const computeMetricsCallback = useCallback(
    async (e) => {
      setWaitingForAPI(true);
      fetch(
        "/ape/api/v1/project/project/computeMetrics/" +
          userId +
          "/" +
          projectId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify({
            metricsContainer,
          }),
        }
      ).then((response) => {
        response.json().then((response) => {
          console.log("Before setting metrics container");
          console.log(metricsContainer);
          const metricsContainerCopy = MetricsContainer.copyMetricsContainer(
            response.container
          );
          console.log(response.container);
          metricsContainerCopy.engineering = _.cloneDeep(
            response.container.engineering
          );
          setMetricsContainer(metricsContainerCopy);
          console.log(metricsContainer);
          setWaitingForAPI(false);
        });
      });
    },
    [metricsContainer]
  );

  const handleSelectionChange = (e) => {
    setSelectedBuilding(e.target.value);
  };

  return (
    <Card w="100%" h="100%">
      <Flex padding="10px" flexDirection="column">
        <Button onClick={computeMetricsCallback} isLoading={waitingForAPI}>
          Compute Metrics
        </Button>
        <Tabs isFitted variant="enclosed">
          <TabList
            mx={{ base: "10px", lg: "30px" }}
            overflowX={{ sm: "scroll", lg: "unset" }}
          >
            <Tab>
              <Icon
                color={textColor}
                as={MdOutlineSummarize}
                w="20px"
                h="20px"
                me="8px"
              />
              <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
                {"Project Summary"}
              </Text>
            </Tab>
            <Tab>
              <Icon
                color={textColor}
                as={MdEngineering}
                w="20px"
                h="20px"
                me="8px"
              />
              <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
                {"Engineering"}
              </Text>
            </Tab>
            <Tab>
              <Icon
                color={textColor}
                as={MdConstruction}
                w="20px"
                h="20px"
                me="8px"
              />
              <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
                {"Construction Costs"}
              </Text>
            </Tab>
            <Tab>
              <Icon color={textColor} as={MdMoney} w="20px" h="20px" me="8px" />
              <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
                {"Finance"}
              </Text>
            </Tab>
            <Tab>
              <Icon
                color={textColor}
                as={MdBatteryChargingFull}
                w="20px"
                h="20px"
                me="8px"
              />
              <Text color={textColor} fontSize="lg" fontWeight="500" me="12px">
                {"Environmental"}
              </Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SimpleGrid minChildWidth="300px" spacing="40px">
                {Object.entries(metricsContainer.getProjectSummary()).map(
                  ([key, value]) => (
                    <Stat
                      label={value.label}
                      target={value.target}
                      icon={value.icon}
                      unit={value.unit}
                      description={value.description}
                    />
                  )
                )}
              </SimpleGrid>
            </TabPanel>
            <TabPanel w="100%" h="100%">
              <Select onChange={handleSelectionChange}>
                <option value="acrossAll">Values Across All Buildings</option>
                {Object.values(metricsContainer.engineering.buildings).map(
                  (building) => (
                    <option
                      key={building.inputs.buildingId}
                      value={building.inputs.buildingId}
                    >
                      {building.inputs.buildingName}
                    </option>
                  )
                )}
              </Select>

              <SimpleGrid minChildWidth="300px" spacing="40px">
                {Object.values(
                  metricsContainer.getStats("engineering", selectedBuilding)
                ).map((value) => (
                  <Stat
                    label={value.label}
                    target={value.target}
                    icon={value.icon}
                    unit={value.unit}
                    description={value.description}
                  />
                ))}
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <Select onChange={handleSelectionChange}>
                <option value="acrossAll">Values Across All Buildings</option>
                {Object.values(metricsContainer.engineering.buildings).map(
                  (building) => (
                    <option
                      key={building.inputs.buildingId}
                      value={building.inputs.buildingId}
                    >
                      {building.inputs.buildingName}
                    </option>
                  )
                )}
              </Select>
              <SimpleGrid minChildWidth="300px" spacing="40px">
                {Object.values(
                  metricsContainer.getStats("cost", selectedBuilding)
                ).map((value) => (
                  <Stat
                    label={value.label}
                    target={value.target}
                    icon={value.icon}
                    unit={value.unit}
                    description={value.description}
                  />
                ))}
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <Select onChange={handleSelectionChange}>
                <option value="acrossAll">Values Across All Buildings</option>
                {Object.values(metricsContainer.engineering.buildings).map(
                  (building) => (
                    <option
                      key={building.inputs.buildingId}
                      value={building.inputs.buildingId}
                    >
                      {building.inputs.buildingName}
                    </option>
                  )
                )}
              </Select>
              <SimpleGrid minChildWidth="300px" spacing="40px">
                  {Object.values(
                    metricsContainer.getStats("financial", selectedBuilding)
                  ).map((value) => (
                    <Stat
                      label={value.label}
                      target={value.target}
                      icon={value.icon}
                      unit={value.unit}
                      description={value.description}
                    />
                  ))}
                </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <Select onChange={handleSelectionChange}>
                <option value="acrossAll">Values Across All Buildings</option>
                {Object.values(metricsContainer.engineering.buildings).map(
                  (building) => (
                    <option
                      key={building.inputs.buildingId}
                      value={building.inputs.buildingId}
                    >
                      {building.inputs.buildingName}
                    </option>
                  )
                )}
              </Select>
              <SimpleGrid minChildWidth="300px" spacing="40px">
                  {Object.values(
                    metricsContainer.getStats("environmental", selectedBuilding)
                  ).map((value) => (
                    <Stat
                      label={value.label}
                      target={value.target}
                      icon={value.icon}
                      unit={value.unit}
                      description={value.description}
                    />
                  ))}
                </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Card>
  );
}
