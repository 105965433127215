import { v4 as uuidv4, v4 } from "uuid";
import turf, { feature } from "turf/turf";
import mapboxgl from "mapbox-gl";
import { WebMercatorViewport } from "@deck.gl/core";
import MetricsContainer from "../objects/MetricsContainer";

const staticClient = require("@mapbox/mapbox-sdk/services/static");
const mbxClient = require("@mapbox/mapbox-sdk");

mapboxgl.accessToken =
  "pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA";
const baseClient = mbxClient({
  accessToken:
    "sk.eyJ1IjoiYXltYW5zIiwiYSI6ImNsM3d3cHJncDA3MmkzY25zY2twcGt3OWMifQ.IPfYGrGOKHl2vURgbWPPQA",
});

const mapboxClient = staticClient(baseClient);

export class BuildingContainer {
  constructor(map, buildingId, buildingPolygon) {
    this.map = map;

    this.buildingId = buildingId;
    this.buildingPolygon = buildingPolygon;

    // Generate name if not provided
    if (this.buildingPolygon.properties.name === undefined) {
      this.buildingPolygon.properties.name = "Building " + this.buildingId;
    }
    this.buildingPolygonSetback = turf.buffer(buildingPolygon, -(4 / 3280.64));
    this.buildingPolygonSetbackId = undefined;

    this.buildingPolygonBounds = this._generatePolygonBounds(
      this.buildingPolygon
    );
    this.buildingCamera = map.cameraForBounds(this.buildingPolygonBounds);

    this.image_width = 600;
    this.image_height = 400;
    this.image = this._getImageUrl();

    this.obstructions = [];
    this.updateVisulization();
  }

  generatePixelCoordinates() {
    const viewport = new WebMercatorViewport({
      width: this.image_width,
      height: this.image_height,
      longitude: this.buildingCamera.center.lng,
      latitude: this.buildingCamera.center.lat,
      zoom: this.buildingCamera.zoom,
      pitch: this.buildingCamera.pitch,
      bearing: this.buildingCamera.bearing,
    });

    var coordinates = [];

    this.buildingPolygon.geometry.coordinates[0].forEach((coordinate) => {
      coordinates.push(viewport.project(coordinate));
    });

    this.pixelCoordinates = coordinates;
  }

  updateVisulization() {
    if (this.buildingPolygonSetbackId === undefined) {
      this.buildingPolygonSetbackId = this.buildingId + ":setback:" + uuidv4();
      this.map.addSource(this.buildingPolygonSetbackId, {
        type: "geojson",
        data: this.buildingPolygonSetback,
      });
      this.map.addLayer(
        {
          id: this.buildingPolygonSetbackId + ":fill",
          type: "fill",
          source: this.buildingPolygonSetbackId,
          paint: {
            "fill-color": "#FFFFFF",
            "fill-opacity": 0.5,
          },
        },
        "buildings"
      );
      this.map.addLayer(
        {
          id: this.buildingPolygonSetbackId + ":line",
          type: "line",
          source: this.buildingPolygonSetbackId,
          paint: {
            "line-color": "#FFFF00",
            "line-width": 3,
            "line-dasharray": [10, 5],
          },
        },
        "buildings"
      );
    } else {
      // Update the setback polygon with all obstructions
      let setbackPolygon = this.buildingPolygonSetback;
      this.obstructions.forEach((obstruction) => {
        setbackPolygon = turf.difference(setbackPolygon, obstruction["buffer"]);
      });

      this.map.getSource(this.buildingPolygonSetbackId).setData(setbackPolygon);
    }
  }

  update(feature) {
    this.buildingPolygon = feature;
    this.buildingPolygonBounds = this._generatePolygonBounds(
      this.buildingPolygon
    );
    this.buildingCamera = this.map.cameraForBounds(this.buildingPolygonBounds);
    this.buildingPolygonSetback = turf.buffer(
      this.buildingPolygon,
      -(4 / 3280.64)
    );
    this.image = this._getImageUrl();
    this.updateVisulization();
  }

  toInferenceFormat() {
    return {
      id: this.buildingId,
      image: this.image,
      coordinates: this.pixelCoordinates,
      centerCoordinates: this.buildingCamera.center,
      buildingPolygon: this.buildingPolygon,
    };
  }

  toTableFormat(metricsContainer) {
    const buildingMetric =
      metricsContainer.engineering.buildings[this.buildingId];
    var type = "Roof";
    var name = "Building " + this.buildingId;
    if (buildingMetric !== undefined) {
      name = buildingMetric.inputs.buildingName;
      type = buildingMetric.selectables.projectType;
    }

    return {
      projectType: type,
      buildingId: this.buildingId,
      buildingName: name,
      totalArea: (
        turf.area(this.buildingPolygon, { units: "feet" }) * 10
      ).toFixed(2),
      totalUsableArea:
        (this.obstructions.reduce((total, obstruction) => {
          return total - turf.area(obstruction["buffer"], { units: "feet" });
        }, turf.area(this.buildingPolygon, { units: "feet" })) * 10).toFixed(2),
      numberOfObstructions: this.obstructions.length,
      clickToView: this.buildingPolygonBounds,
    };
  }

  toMetricsFormat(metricsContainer) {
    const buildingMetric =
      metricsContainer.engineering.buildings[this.buildingId];
    var type = "Roof";
    var name = "Building " + this.buildingId;
    if (buildingMetric !== undefined) {
      name = buildingMetric.inputs.buildingName;
      type = buildingMetric.selectables.projectType;
    }

    return {
      buildingId: this.buildingId,
      buildingName: name,
      projectType: type,
      azimuth: 180,
      buildingPolygon: this.buildingPolygon,
      obstructions: this.obstructions.map((obstruction) => {
        return obstruction.obstruction;
      }),
      totalArea:
        turf.area(this.buildingPolygon, { units: "feet" }).toFixed(2) * 10,
      totalUsableArea:
        this.obstructions
          .reduce((total, obstruction) => {
            return total - turf.area(obstruction["buffer"], { units: "feet" });
          }, turf.area(this.buildingPolygon, { units: "feet" }))
          .toFixed(2) * 10,
      numberOfObstructions: this.obstructions.length,
    };
  }

  addObstruction(obstruction) {
    // Create new feature with obstruction id
    const new_obstruction = { ...obstruction };
    new_obstruction.id = this.buildingId + ":obstruction:" + uuidv4();
    const obstructionBuffer = turf.buffer(obstruction, 4 / 3280.64);

    this.obstructions.push({
      obstruction: new_obstruction,
      buffer: obstructionBuffer,
    });
    this.updateVisulization();
    return new_obstruction;
  }

  addManyObstructions(obstructions, draw = undefined) {
    obstructions.forEach((obstruction) => {
      const new_obstruction = { ...obstruction };
      new_obstruction.id = obstruction.id;

      if (draw !== undefined) {
        draw.add(new_obstruction);
      }
      const obstructionBuffer = turf.buffer(obstruction, 4 / 3280.64);

      this.obstructions.push({
        obstruction: new_obstruction,
        buffer: obstructionBuffer,
      });
    });
    this.updateVisulization();
  }

  delete(draw) {
    // Delete all obstructions
    this.obstructions.forEach((obstruction) => {
      setTimeout(() => {
        draw.delete(obstruction["obstruction"].id);
      }, 100);
    });
    this.map.removeLayer(this.buildingPolygonSetbackId + ":fill");
    this.map.removeLayer(this.buildingPolygonSetbackId + ":line");
    this.map.removeSource(this.buildingPolygonSetbackId);
  }

  updateObstruction(obstruction) {
    // Update the obstruction
    const obstructionIndex = this.obstructions.findIndex(
      (obstruction_) => obstruction_.obstruction.id === obstruction.id
    );
    this.obstructions[obstructionIndex].obstruction = obstruction;
    this.obstructions[obstructionIndex].buffer = turf.buffer(
      obstruction,
      4 / 3280.64
    );
    this.updateVisulization();
  }

  removeObstruction(obstruction) {
    // Remove the obstruction
    const obstructionIndex = this.obstructions.findIndex(
      (obstruction_) => obstruction_.obstruction.id === obstruction.id
    );
    this.obstructions.splice(obstructionIndex, 1);
    this.updateVisulization();
  }

  _getImageUrl() {
    // _getImageUrl calls the mapbox static image api to
    // retrieve a url to the image of the building with the optimal
    // camera settings to show the building in its entirety.

    // TODO: support large building tilling

    // Generate deck gl viewport for custom camera with image and height
    const viewport = new WebMercatorViewport({
      width: this.image_width,
      height: this.image_height,
      longitude: this.buildingCamera.center.lng,
      latitude: this.buildingCamera.center.lat,
      zoom: this.buildingCamera.zoom,
      pitch: this.buildingCamera.pitch,
      bearing: this.buildingCamera.bearing,
    });

    const reformatedViewport = viewport.fitBounds([
      [this.buildingPolygonBounds._ne.lng, this.buildingPolygonBounds._ne.lat],
      [this.buildingPolygonBounds._sw.lng, this.buildingPolygonBounds._sw.lat],
    ]);
    const request = mapboxClient.getStaticImage({
      ownerId: "aymans",
      styleId: "cl61pb0nh000915oh87bl1x4d",
      width: this.image_width,
      height: this.image_height,
      logo: false,
      position: {
        coordinates: [
          this.buildingCamera.center.lng,
          this.buildingCamera.center.lat,
        ],
        zoom: reformatedViewport.zoom > 20 ? 20 : reformatedViewport.zoom,
        bearing: this.buildingCamera.bearing,
      },
    });

    return request.url();
  }

  _generatePolygonBounds(buildingPolygon) {
    // _generatePolygonBounds returns bbox that wraps a polgon object

    // Convert polygon to bbox to get camera bounds
    const bbox = turf.bbox(buildingPolygon);

    const bounds = new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(bbox[2], bbox[3]),
      new mapboxgl.LngLat(bbox[0], bbox[1])
    );

    return bounds;
  }
}

export function createBuilding(
  map,
  buildingFeature,
  obstructions,
  selectedBuildings,
  setBuildingTableData,
  metricsContainer,
  setMetricsContainer,
  projectDefaults
) {
  const building = new BuildingContainer(
    map,
    buildingFeature.id,
    buildingFeature
  );

  building.addManyObstructions(obstructions);

  selectedBuildings[building.buildingId] = building;
  // Update the buildingTableData with all selected buildings
  const tableData = Object.values(selectedBuildings).map((building) => {
    return building.toTableFormat(metricsContainer);
  });
  setBuildingTableData(tableData);

  const metricsContainerCopy =
    MetricsContainer.copyMetricsContainer(metricsContainer);

  Object.values(selectedBuildings).map((building) => {
    metricsContainerCopy.addBuilding(
      building.toMetricsFormat(metricsContainer),
      projectDefaults
    );
  });

  setMetricsContainer(metricsContainerCopy);
}
