import React from "react";
import ReactDOM from "react-dom";

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import Safari from "layouts/safari";

import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";

import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from '@stytch/vanilla-js';

import theme from "theme/theme";

// Chakra imports
import { ChakraProvider } from "@chakra-ui/react";

const stytch = new StytchUIClient(
  "public-token-test-4f6c7204-bc0d-4df2-940f-0ad1bac689ed"
);


ReactDOM.render(
  <StytchProvider stytch={stytch}>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/safari`} component={Safari} />
            <Redirect from="/" to="/auth" />
          </Switch>
        </HashRouter>
      </React.StrictMode>
    </ChakraProvider>
  </StytchProvider>,
  document.getElementById("root")
);
