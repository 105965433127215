import turf from "turf/turf";
import { BuildingContainer } from "./BuildingContainer";
import MetricsContainer from "../objects/MetricsContainer";
export function setMapDrawUpdates(
  map,
  selectedBuildings,
  draw,
  setBuildingTableData,
  metricsContainer,
  setMetricsContainer,
  projectDefaultParams
) {
  map.on("draw.update", function (e) {
    e.features.forEach(function (feature) {
      const id = feature.id;
      // Update if the feature is a selected building
      if (id in selectedBuildings) {
        selectedBuildings[id].update(feature);
      }

      // If the feature is a selected obstruction
      else if (id.includes("obstruction")) {
        const buildingId = id.split(":")[0];
        selectedBuildings[buildingId].updateObstruction(feature);
      }
    });

    // Update the buildingTableData with all selected buildings
    const tableData = Object.values(selectedBuildings).map((building) => {
      return building.toTableFormat(metricsContainer);
    });
    setBuildingTableData(tableData);

    const metricsContainerCopy =
        MetricsContainer.copyMetricsContainer(metricsContainer);

      Object.values(selectedBuildings).map((building) => {
        metricsContainerCopy.addBuilding(building.toMetricsFormat(metricsContainer), projectDefaultParams);
      });

      setMetricsContainer(metricsContainerCopy);
  });

  map.on("draw.create", function (e) {
    if (e.features[0].geometry.type === "Polygon") {
      const id = e.features[0].id;

      var associated = false;

      // Check if a polygon intersects with any other selected buildings
      for (const buildingId in selectedBuildings) {
        const intersection = turf.intersect(
          e.features[0].geometry,
          selectedBuildings[buildingId].buildingPolygon
        );

        if (intersection !== undefined) {
          const percentage =
            turf.area(intersection) / turf.area(e.features[0].geometry);
          if (percentage > 0.5) {
            const new_id = selectedBuildings[buildingId].addObstruction(
              e.features[0]
            );
            draw.delete(id);
            draw.add(new_id);
            associated = true;
          }
        }
      }

      if (!associated) {
        selectedBuildings[id] = new BuildingContainer(map, id, e.features[0]);
      }

      // Update the buildingTableData with all selected buildings
      const tableData = Object.values(selectedBuildings).map((building) => {
        return building.toTableFormat(metricsContainer);
      });
      setBuildingTableData(tableData);

      const metricsContainerCopy =
        MetricsContainer.copyMetricsContainer(metricsContainer);

      Object.values(selectedBuildings).map((building) => {
        metricsContainerCopy.addBuilding(building.toMetricsFormat(metricsContainer), projectDefaultParams);
      });

      setMetricsContainer(metricsContainerCopy);
    }
  });

  map.on("draw.delete", function (e) {
    e.features.forEach(function (feature) {
      const id = feature.id;
      // Update if the feature is a selected building
      if (id in selectedBuildings) {
        selectedBuildings[id].delete(draw);
        delete selectedBuildings[id];
        metricsContainer.removeBuilding(id);

      }

      // If the feature is a selected obstruction
      else if (id.includes("obstruction")) {
        const buildingId = id.split(":")[0];
        selectedBuildings[buildingId].removeObstruction(feature);
      }
    });
    // Update the buildingTableData with all selected buildings
    const tableData = Object.values(selectedBuildings).map((building) => {
      return building.toTableFormat(metricsContainer);
    });
    setBuildingTableData(tableData);
    const metricsContainerCopy =
        MetricsContainer.copyMetricsContainer(metricsContainer);
      console.log(metricsContainer);
      Object.values(selectedBuildings).map((building) => {
        metricsContainerCopy.addBuilding(building.toMetricsFormat(metricsContainer), projectDefaultParams);
      });

      setMetricsContainer(metricsContainerCopy);
      console.log(metricsContainer);
  });
}
