import MapboxDraw from "@mapbox/mapbox-gl-draw";
import turf from "turf/turf";
import MetricsContainer from "../objects/MetricsContainer";
import { BuildingContainer } from "./BuildingContainer";

import polygon_styles from "./Styles";

export function buildBuildingSelectionTool(
  map,
  selectedBuildings,
  setBuildingTableData,
  metricsContainer,
  setMetricsContainer,
  projectDefaultParams
) {
  // buildBuildingSelectionTool builds a mapbox Control that allows the user to select buildings on the map
  // This builds upon the current draw features of mapbox-gl-draw

  const SelectBuildingMode = {};

  // Initalize mapbox draw control
  const draw = new MapboxDraw({
    displayControlsDefault: false,
    userProperties: true,
    styles: polygon_styles,
    controls: {
      polygon: true,
      trash: true,
    },
    modes: Object.assign(
      {
        buildingSelect: SelectBuildingMode,
      },
      MapboxDraw.modes
    ),
  });

  // In order to add this control a custom object must be created
  // This custom object requires a onAdd, onRemove, and onSetup function
  SelectBuildingMode.onSetup = function (opts) {
    return {};
  };

  SelectBuildingMode.onClick = function (state, e) {
    // Retrieve all features under the click event
    console.log(e.point);
    const features = map.queryRenderedFeatures(e.point, {
      filter: ["==", "extrude", "true"],
      validate: true,
    });

    // If no features are present, return
    if (features.length === 0) {
      return;
    }

    // Reduce all features to the one with the greatest area
    const selectedFeature = features.reduce(
      (max, f) => (turf.area(f) > turf.area(max) ? f : max),
      features[0]
    );

    // If feature id is undefined return
    if (selectedFeature.id === undefined) {
      return;
    }

    // Filter an additional layer of features to only include buildings
    // Sometimes mapbox will return sub features of buildings, this is due
    // to the way mapbox renders buildings (Tilequery), there fore we must
    // merge all features with the same id into one feature
    const allFeaturesWithSameId = map.querySourceFeatures("composite", {
      sourceLayer: "building",
      filter: ["==", "$id", selectedFeature.id],
    });

    // Create union of all features with the same id
    // Union can only merge two features at a time
    const mergedFeature = allFeaturesWithSameId.reduce((a, b) =>
      turf.union(a, b)
    );

    // Override turf feature to include id and properties
    const selectedBuildingFeature = {
      type: "Feature",
      id: selectedFeature.id,
      properties: {
        class_id: 1,
      },
      geometry: {
        type: "Polygon",
        coordinates: mergedFeature.geometry.coordinates,
      },
    };

    if (selectedBuildingFeature.id in selectedBuildings) {
      // If the building is already selected, create a new object without the building
      selectedBuildings[selectedBuildingFeature.id].delete();
      delete selectedBuildings[selectedBuildingFeature.id];

      // Update the map features to remove the selected building
      draw.delete(selectedFeature.id);
      const metricsContainerCopy =
        MetricsContainer.copyMetricsContainer(metricsContainer);
      metricsContainerCopy.removeBuilding(selectedBuildingFeature.id);
      setMetricsContainer(metricsContainerCopy);
    } else {
      // If the building is not already selected, create a new object with the building
      selectedBuildings[selectedBuildingFeature.id] = new BuildingContainer(
        map,
        selectedBuildingFeature.id,
        selectedBuildingFeature
      );

      // Update the map features to add the selected building
      draw.add(selectedBuildingFeature);
      

      const metricsContainerCopy =
        MetricsContainer.copyMetricsContainer(metricsContainer);
        
      Object.values(selectedBuildings).map((building) => {
        metricsContainerCopy.addBuilding(building.toMetricsFormat(metricsContainer), projectDefaultParams);
      });

      setMetricsContainer(metricsContainerCopy);
    }
      
    // Update the buildingTableData with all selected buildings
    const tableData = Object.values(selectedBuildings).map((building) => {
      return building.toTableFormat(metricsContainer);
    });
    setBuildingTableData(tableData);
  };

  SelectBuildingMode.onKeyUp = function (state, e) {
    if (e.keyCode === 27) return this.changeMode("simple_select");
  };

  SelectBuildingMode.toDisplayFeatures = function (state, geojson, display) {
    display(geojson);
  };

  return draw;
}
