import React from "react";
import Popup from "reactjs-popup";

import Card from "components/card/Card";
import {
  Button,
  Flex,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useDropzone } from "react-dropzone";
import { useStytchUser } from "@stytch/react";
import { BsFileBinary } from "react-icons/bs";

export default function BulkCSVUpload(props) {
  const { open, setOpen } = { ...props };
  const closeModal = () => setOpen(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgButton = useColorModeValue("secondaryGray.600", "white");

  const [projectName, setProjectName] = React.useState("");
  const [runMl, setRunMl] = React.useState(false);
  const stytchUser = useStytchUser();

  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const [fileBinary, setFileBinary] = React.useState(false);

  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setFileBinary(file);
    })
  }, [])

  const handleUploadClick = () => {
    
    const email = stytchUser.user.emails[0].email;
    
    const body= new FormData()
    body.append('file', fileBinary)

    fetch("/ape/api/v1/project/upload_csv/" + stytchUser.user.user_id + "/" + email, {
      method: "POST",
      body: body,
      })
      .then((response) => response.json())
      .then((data) => {
      });
  };
  
    const { getRootProps, getInputProps } = useDropzone({onDrop});

  return (
    <Popup
      class="popup-content"
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Flex w={{ sm: "330px", md: "700px", lg: "850px" }}>
        <Card p="30px">
          <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
            Bulk Process Projects
          </Text>
          <Flex direction="column" w="100%">
            <Stack direction="column" spacing="20px">
              <SimpleGrid columns={{ base: "1" }} gap="40px">
                <Flex
                  align='center'
                  justify='center'
                  bg={bg}
                  border='1px dashed'
                  borderColor={borderColor}
                  borderRadius='16px'
                  w='100%'
                  h='max-content'
                  minH='100%'
                  cursor='pointer'
                  {...getRootProps({ className: "dropzone" })}
                  >
                  <Input variant='main' {...getInputProps()} />
                  <Button >Upload Bulk Address</Button>
                </Flex>
              </SimpleGrid>
            </Stack>
            <Flex justify="space-between" mt="24px">
              <Button
                variant="darkBrand"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: "128px", md: "148px" }}
                h="46px"
                ms="auto"
                onClick={handleUploadClick}
              >
                Next
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Popup>
  );
}
