import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdHome, MdDashboard, MdPerson, MdSettings, MdGetApp, MdOutlineDisabledByDefault } from "react-icons/md";

// App Imports
import Project from "views/app/projects/Project.jsx";
import SafariProjects from "views/app/projects/SafariProjects.jsx";
import UserMetricsDefaults from "views/app/projects/UserMetricsDefaults.jsx";
import SafariUserDashboard from "views/app/projects/SafariUserDashboard";
const routes = [
  {
    name: "Safari App",
    path: "/safari",
    collapse: true,
    icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
    items: [

      {
        name: "Safari Projects",
        layout: "/safari",
        path: "/project/:id",
        component: Project,
        exact: false,
        display: false,
        icon: MdDashboard,

      },
      {
        name: "Projects",
        layout: "/safari",
        path: "/projects",
        component: SafariProjects,
        exact: false,
        display: true,
        icon: MdDashboard,
      },
      {
        name: "Invite Users",
        layout: "/safari",
        path: "/users",
        component: SafariUserDashboard,
        exact: false,
        display: true,
        requiresAdmin: true,
        icon: MdPerson,
      },
      {
        name: "User Metrics Defaults",
        layout: "/safari",
        path: "/user-metrics-defaults",
        component: UserMetricsDefaults,
        display: true,
        exact: false,
        icon: MdOutlineDisabledByDefault,
      }
    ]
  },
];

export default routes;
