import React, { useEffect, useState } from "react";
import UserMetricsDefaultsForm from "./components/userMetricsForms";
import { useStytchUser } from "@stytch/react";
import { GiConsoleController } from "react-icons/gi";
import Card from "components/card/Card";

export default function UserMetricsDefaults() {
  const [formInputs, setFormInputs] = useState({});
  const stytchUser = useStytchUser();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    fetch(
      "/ape/api/v1/project/project/formInputs/" + stytchUser.user.user_id
    ).then((response) => {
      response.json().then((response) => {
        const data = response;
        setFormInputs(data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    });
  }, []);

  return loading ? (
    <div />
  ) : (
    <Card>
    <UserMetricsDefaultsForm
      formInputs={formInputs}
      setFormInputs={setFormInputs}
    />
    </Card>
  );
}
