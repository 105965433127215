export function buildMapBoxConfig(container, styles, center) {
  return {
    container,
    style: styles,
    center: [center[0], center[1]],
    zoom: 17,
    maxZoom: 40,
  };
}

export const mapBoxVectorLayer = {
  type: "vector",
  url: "mapbox://mapbox.82pkq93d",
};
