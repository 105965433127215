import React from "react";
import { useForm } from "react-hook-form";

import {
  Divider,
  Button,
  Input,
  Heading,
  NumberInput,
  NumberInputField,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Select,
  Flex,
  useToast,
} from "@chakra-ui/react";

import { useStytchUser } from "@stytch/react";
import { GiConsoleController } from "react-icons/gi";

export default function SafariUserDashboard() {
  const stytchUser = useStytchUser();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onProjectTypeChange = (event) => {
    const value = event.target.value;
    // Overide building table name and project type
  };

  const toast = useToast();

  function onSubmit(values) {
    const instanceParameters = values;

    const userSubmit = {
      email: instanceParameters.email,
      firstName: instanceParameters.firstName,
      lastName: instanceParameters.lastName,
    };

    const api_url = "/ape/api/v1/user/invite";
    fetch(api_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userSubmit),
    }).then((response) => {
      response.json().then((data) => {
        if (data.status_code == 200) {
          toast({
            title: "Account created.",
            description: "Succesfully invited user.",
            status: "success",
            duration: 9000,
            position: "top",
            isClosable: true,
          });
        } else {
          toast({
            title: "Account creation failed.",
            description: "Failed to invite user.",
            status: "error",
            duration: 9000,
            position: "top",
            isClosable: true,
          });
        }
      });
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} align="center" w="50%">
      <FormControl id="parameterForm" isRequired>
        <Heading size="md">Invite Users</Heading>

        <FormLabel>First Name</FormLabel>
        <Input id="firstName" {...register("firstName", { required: true })} />
        <FormLabel>Last Name</FormLabel>
        <Input id="lasttName" {...register("lastName", { required: true })} />
        <FormLabel>Email</FormLabel>
        <Input id="email" {...register("email", { required: true })} />
      </FormControl>
      <Button mt={4} colorScheme="teal" isLoading={isSubmitting} type="submit">
        Invite
      </Button>
    </form>
  );
}
