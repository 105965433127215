import React from "react";
import { useForm } from "react-hook-form";

import {
  Divider,
  Button,
  Input,
  Heading,
  NumberInput,
  NumberInputField,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Select,
  Flex,
} from "@chakra-ui/react";

import { useStytchUser } from "@stytch/react";
import { GiConsoleController } from "react-icons/gi";

export default function UserMetricsDefaultsForm(props) {
  const { formInputs, setFormInputs } = props;
  const stytchUser = useStytchUser();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onProjectTypeChange = (event) => {
    const value = event.target.value;
    // Overide building table name and project type
  };

  function onSubmit(values) {
    const instanceParameters = values;
  }

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex
        align={{ lg: "center" }}
        justify={{ base: "space-between" }}
        w="100%"
        px="30px"
        mb="20px"
      >
        <form onSubmit={handleSubmit(onSubmit)} align="center" w="50%">
          <FormControl id="parameterForm" isRequired>
            <Heading size="md">Instance Parameters</Heading>

            <FormLabel>Instance Type</FormLabel>
            <Select
              id="instanceType"
              {...register("instanceType", { required: true })}
              onChange={onProjectTypeChange}
            >
              <option value="ROOF">ROOF</option>
              <option value="CANOPY">CANOPY</option>
            </Select>
            <FormHelperText>Project Instance Type.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <Divider orientation="horizontal" />

            <Heading size="md">Engineering Parameters</Heading>

            <FormLabel>Racking Type</FormLabel>
            <Select {...register("rackingType", { required: true })}>
              {formInputs.engineering.rackingModules.map((rackingModule) => {
                return (
                  <option value={rackingModule.moduleName}>
                    {rackingModule.moduleName}
                  </option>
                );
              })}
            </Select>

            <FormHelperText>Racking Module for Instance Type.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Solar Module Selection</FormLabel>
            <Select {...register("solarModule", { required: true })}>
              {formInputs.engineering.solarModules.map((solarModule) => {
                return (
                  <option value={solarModule.moduleName}>
                    {solarModule.moduleName}
                  </option>
                );
              })}
            </Select>

            <FormHelperText>Solar Module for Instance Type.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Inverter Manufacturer Selection</FormLabel>
            <Select {...register("inverterManufacturer", { required: true })}>
              {formInputs.engineering.inverterManufacturers.map(
                (inverterManufacturer) => {
                  return (
                    <option value={inverterManufacturer.manufacturerName}>
                      {inverterManufacturer.manufacturerName}
                    </option>
                  );
                }
              )}
            </Select>

            <FormHelperText>
              Inverter Manufacturer for Instance Type.
            </FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <Divider orientation="horizontal" />

            <Heading size="md">Cost/Construction Parameters</Heading>

            <FormLabel>Electrical Feed</FormLabel>
            <Select {...register("electricalFeed", { required: true })}>
              {formInputs.cost.electricalFeeders.map((electricalFeeder) => {
                return (
                  <option value={electricalFeeder.electricalFeedName}>
                    {electricalFeeder.electricalFeedName}
                  </option>
                );
              })}
            </Select>

            <FormHelperText>
              Type of electrical feed used for construction.
            </FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Labor Type</FormLabel>
            <Select {...register("laborType", { required: true })}>
              {formInputs.cost.laborTypes.map((laborType) => {
                return (
                  <option value={laborType.laborTypeName}>
                    {laborType.laborTypeName}
                  </option>
                );
              })}
            </Select>

            <FormHelperText>
              Type of labor used for construction.
            </FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Tax Type</FormLabel>
            <Select {...register("taxType", { required: true })}>
              {formInputs.cost.taxTypes.map((taxType) => {
                return (
                  <option value={taxType.taxTypeName}>
                    {taxType.taxTypeName}
                  </option>
                );
              })}
            </Select>

            <FormHelperText>Tax type for the construction.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Tax Rate [$]</FormLabel>

            <NumberInput
              max={100.0}
              min={0.0}
              {...register("taxRate", { required: true })}
            >
              <NumberInputField />
            </NumberInput>
            <FormHelperText>Tax type for the construction.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Added Extra Cost [$]</FormLabel>

            <NumberInput
              min={0.0}
              {...register("addedExtraCost", { required: true })}
            >
              <NumberInputField />
            </NumberInput>
            <FormHelperText>Tax type for the construction.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Number of Meters On Site [m]</FormLabel>

            <NumberInput
              min={0.0}
              {...register("numMetersOnSite", { required: true })}
            >
              <NumberInputField />
            </NumberInput>
            <FormHelperText>Tax type for the construction.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Project Target Margin</FormLabel>

            <NumberInput
              min={0.0}
              {...register("projectTargetMargin", { required: true })}
            >
              <NumberInputField />
            </NumberInput>
            <FormHelperText>Tax type for the construction.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Commodity Adjustment</FormLabel>

            <NumberInput
              min={0.0}
              max={1.0}
              {...register("commodityAdjustment", { required: true })}
            >
              <NumberInputField />
            </NumberInput>
            <FormHelperText>Commodity Adjustment.</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Module Spot Cost</FormLabel>

            <NumberInput
              min={0.0}
              max={1.0}
              {...register("moduleSpotCost", { required: true })}
            >
              <NumberInputField />
            </NumberInput>
            <FormHelperText>Module Spot Cost</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Inverter Cost</FormLabel>

            <NumberInput min={0.0} {...register("invCost", { required: true })}>
              <NumberInputField />
            </NumberInput>
            <FormHelperText>Inverter Cost</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>

            <FormLabel>Opt Cost</FormLabel>

            <NumberInput min={0.0} {...register("optCost", { required: true })}>
              <NumberInputField />
            </NumberInput>
            <FormHelperText>Opt Cost</FormHelperText>
            <FormErrorMessage>
              {errors.email && "Email is required"}
            </FormErrorMessage>
          </FormControl>

          <Button
            mt={4}
            colorScheme="teal"
            isLoading={isSubmitting}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </Flex>
    </Flex>
  );
}
