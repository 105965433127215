import {
  Avatar,
  Button,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Spinner,
  Badge,
  useDisclosure,
  IconButton,
  Checkbox,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import React, { useMemo } from "react";
import { AddIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { MdReceipt } from "react-icons/md";
// Custom components
import { useStytchUser } from "@stytch/react";
import { useHistory } from "react-router-dom";
import Card from "components/card/Card.js";
import NewProjectForm from "components/popup/NewProjectForm";
import BulkCSVUpload from "./BulkCSVUpload";

// Assets
import { MdEdit } from "react-icons/md";
import { NavLink, Redirect } from "react-router-dom";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function SafariProjectsTable(props) {
  const { columnsData, tableData, setTableData, map } = props;

  const stytchUser = useStytchUser();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const history = useHistory();

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
  } = tableInstance;

  const [openNewProjectForm, setOpenNewProjectForm] = React.useState(false);
  const [openBulkCSVUpload, setOpenBulkCSVUpload] = React.useState(false);
  const [checkedRows, setCheckedRows] = React.useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const addProjectClick = async () => {
    setOpenNewProjectForm(true);
  };

  const uploadCSVClick = async () => {
    setOpenBulkCSVUpload(true);
  };

  const handleCheckbox = (e) => {
    const id = e.target.id;
    if (checkedRows.includes(id)) {
      setCheckedRows(checkedRows.filter((item) => item !== id));
    }
    if (!checkedRows.includes(id)) {
      setCheckedRows([...checkedRows, id]);
    }
  };

  const selectAllProjects = () => {
    setCheckedRows(tableData.map((item) => item.projectId));
  };

  const deselectAllProjects = (e) => {
    setCheckedRows([]);
  };

  const deleteSelectedProjects = async () => {
    // Send all selected projects to the backend
    // to delete them
    console.log(checkedRows);
    fetch("/ape/api/v1/project/delete/" + stytchUser.user.user_id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(checkedRows),
    })
      .then((response) => response.json())
      .then((data) => {
        setTableData(data.data);
      });
  };

  const { pageIndex, pageSize } = state;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandText = useColorModeValue("brand.500", "white");
  return (
    <>
      <Card w="100%">
        <Flex
          direction="column"
          w="100%"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex
            align={{ lg: "center" }}
            justify={{ base: "space-between" }}
            w="100%"
            px="30px"
            mb="20px"
          >
            <Text
              color={textColor}
              fontSize="xl"
              fontWeight="600"
              lineHeight="100%"
            >
              Projects
            </Text>
            <Flex align="center" justify="flex-end">
              <IconButton
                w="50px"
                h="35px"
                borderRadius="12px"
                title="Bulk Process Projects"
                aria-label="Search database"
                bg={bgButton}
                onClick={uploadCSVClick}
                icon={<MdReceipt w="12px" h="12px" color={brandText} />}
              />
              <BulkCSVUpload
                open={openBulkCSVUpload}
                setOpen={setOpenBulkCSVUpload}
              />
              <IconButton
                w="50px"
                h="35px"
                borderRadius="12px"
                title="Download Selected Projects"
                aria-label="Search database"
                bg={bgButton}
                icon={<DownloadIcon w="12px" h="12px" color={brandText} />}
              />
              <IconButton
                w="50px"
                h="35px"
                borderRadius="12px"
                aria-label="Search database"
                title="Delete Selected Projects"
                onClick={deleteSelectedProjects}
                bg={bgButton}
                icon={<DeleteIcon w="12px" h="12px" color={brandText} />}
              />
              <IconButton
                w="50px"
                h="35px"
                borderRadius="12px"
                aria-label="Search database"
                title="Add New Project"
                bg={bgButton}
                onClick={onOpen}
                icon={<AddIcon w="12px" h="12px" color={brandText} />}
              />
              <NewProjectForm
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                map={map}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          w="100%"
          overflowX={{ sm: "scroll", lg: "scroll" }}
          sx={{
            "&::-webkit-scrollbar": {
              width: "16px",
              borderRadius: "8px",
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
          }}
        >
          <Flex
            align={{ sm: "flex-start", lg: "flex-start" }}
            justify={{ sm: "flex-start", lg: "flex-start" }}
            w="100%"
            px="22px"
            mb="36px"
          >
            <SearchBar
              onChange={(e) => setGlobalFilter(e.target.value)}
              h="44px"
              w={{ lg: "390px" }}
              borderRadius="16px"
            />
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      {/*If its Project Name add a checkbox that selects all projects */}

                      <Flex
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.Header === "Project Name" ? (
                          <Checkbox
                            me="10px"
                            bg={bgButton}
                            borderColor="secondaryGray.400"
                            colorScheme="brandScheme"
                            onChange={(e) => {
                              if (e.target.checked) {
                                selectAllProjects();
                              } else {
                                deselectAllProjects();
                              }
                            }}
                          />
                        ) : null}
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    _checked={true}
                    cursor="pointer"
                    _hover={{ bg: "secondaryGray.100" }}
                    onClick={() => {
                      map.jumpTo({
                        center: row.original.projectCoordinates,
                        essential: true,
                      });
                    }}
                  >
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Project Name") {
                        data = (
                          <Flex align="center">
                            <Checkbox
                              colorScheme="brandScheme"
                              me="10px"
                              bg={bgButton}
                              borderColor="secondaryGray.400"
                              onChange={(e) => handleCheckbox(e)}
                              id={row["original"]["projectId"]}
                            />
                            <Text
                              color="secondaryGray.500"
                              fontSize="sm"
                              fontWeight="600"
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Project Client") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Project ID") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Address") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "DC Size") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {numberWithCommas(cell.value)}
                          </Text>
                        );
                      } else if (cell.column.Header === "Specific Production") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {numberWithCommas(cell.value)}
                          </Text>
                        );
                      } else if (cell.column.Header === "Construction Cost $/W") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {numberWithCommas(cell.value)}
                          </Text>
                        );
                      } else if (cell.column.Header === "Contract Price $/W") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {numberWithCommas(cell.value)}
                          </Text>
                        );
                      } else if (cell.column.Header === "IRR") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {numberWithCommas(cell.value)}
                          </Text>
                        );
                      } else if (cell.column.Header === "PPA Rate") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {numberWithCommas(cell.value)}
                          </Text>
                        );
                      } else if (cell.column.Header === "Payback Period") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {"Years: " + cell.value["Years"] + " Months: " + cell.value["Months"]}
                          </Text>
                        );
                      } else if (cell.column.Header === "Carbon Offset (tons/yr)") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {numberWithCommas(cell.value)}
                          </Text>
                        );
                      } 

                      else if (cell.column.Header === "Status") {
                        data =
                          cell.value === "PENDING" ? (
                            <Badge
                              colorScheme={
                                cell.value === "PENDING" ? "yellow" : "green"
                              }
                              color={
                                cell.value === "PENDING"
                                  ? "yellow.500"
                                  : "green.500"
                              }
                              fontSize="sm"
                              fontWeight="600"
                            >
                              {cell.value.toLowerCase()}&nbsp;&nbsp;&nbsp;
                              <Spinner size="sm" color="black" />
                            </Badge>
                          ) : (
                            <Badge
                              colorScheme={
                                cell.value === "REJECTED" ? "yellow" : "green"
                              }
                              color={
                                cell.value === "REJECTED"
                                  ? "yellow.500"
                                  : "green.500"
                              }
                              fontSize="sm"
                              fontWeight="600"
                            >
                              {"PROCESSED"}
                            </Badge>
                          );
                      } else if (cell.column.Header === " ") {
                        data = (
                          <Button
                            onClick={() => {
                              history.push(
                                "/safari/project/:" +
                                  row["original"]["projectId"]
                              );
                            }}
                          >
                            <Icon
                              color="secondaryGray.500"
                              as={MdEdit}
                              w="20px"
                              h="20px"
                            />
                            &nbsp;Edit
                          </Button>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "120px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                          mt="20px !important"
                          py="10px !important"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify="space-between"
            align="center"
            w="100%"
            px={{ md: "22px" }}
          >
            <Text
              fontSize="sm"
              color="gray.500"
              fontWeight="normal"
              mb={{ sm: "24px", md: "0px" }}
            >
              Showing {pageSize * pageIndex + 1} to{" "}
              {pageSize * (pageIndex + 1) <= tableData.length
                ? pageSize * (pageIndex + 1)
                : tableData.length}{" "}
              of {tableData.length} entries
            </Text>
            <Stack
              direction="row"
              alignSelf={{ base: "unset", md: "flex-end" }}
              spacing="4px"
              ms={{ base: "0px", md: "auto" }}
            >
              <Button
                variant="no-effects"
                onClick={() => previousPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue("gray.200", "white")}
                display={
                  pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
                }
                _hover={{
                  bg: "whiteAlpha.100",
                  opacity: "0.7",
                }}
              >
                <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
              </Button>
              {pageSize === 5 ? (
                <NumberInput
                  max={pageCount - 1}
                  min={1}
                  w="75px"
                  mx="6px"
                  defaultValue="1"
                  onChange={(e) => gotoPage(e)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper onClick={() => nextPage()} />
                    <NumberDecrementStepper onClick={() => previousPage()} />
                  </NumberInputStepper>
                </NumberInput>
              ) : (
                createPages(pageCount).map((pageNumber, index) => {
                  return (
                    <Button
                      variant="no-effects"
                      transition="all .5s ease"
                      onClick={() => gotoPage(pageNumber - 1)}
                      w="40px"
                      h="40px"
                      borderRadius="50%"
                      bg={
                        pageNumber === pageIndex + 1
                          ? brandColor
                          : "transparent"
                      }
                      border={
                        pageNumber === pageIndex + 1
                          ? "none"
                          : "1px solid lightgray"
                      }
                      _hover={
                        pageNumber === pageIndex + 1
                          ? {
                              opacity: "0.7",
                            }
                          : {
                              bg: "whiteAlpha.100",
                            }
                      }
                      key={index}
                    >
                      <Text
                        fontSize="sm"
                        color={
                          pageNumber === pageIndex + 1 ? "#fff" : textColor
                        }
                      >
                        {pageNumber}
                      </Text>
                    </Button>
                  );
                })
              )}
              <Button
                variant="no-effects"
                onClick={() => nextPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue("gray.200", "white")}
                display={
                  pageSize === 5 ? "none" : canNextPage ? "flex" : "none"
                }
                _hover={{
                  bg: "whiteAlpha.100",
                  opacity: "0.7",
                }}
              >
                <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
              </Button>
            </Stack>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}

export default SafariProjectsTable;
