import React, { useEffect } from "react";

import { Flex } from "@chakra-ui/react";
import { useStytchUser } from "@stytch/react";

import SafariProjectsTable from "./components/SafariProjectsTable";
import { tableColumnsManagement } from "./variables/tableColumnsProjects";
import ProjectsMapView from "./components/ProjectsMapView";
import { cost_metrics } from "./variables/metrics";

import { useStytch } from "@stytch/react";
import { useStytchSession } from "@stytch/react";
export default function SafariProjects() {
  const stytchUser = useStytchUser();

  const stytchClient = useStytch();
  const { session } = useStytchSession();

  const [map, setMap] = React.useState(
    React.useMemo(() => {
      return {
        center: {
          lat: 51.5,
          lng: -0.12,
        },
        zoom: 8,
        maxZoom: 35,
      };
    }, [])
  );

  useEffect(() => {
    console.log(session);
    if (session) {
      console.log(stytchUser);
    } else {
      const token = new URLSearchParams(window.location.search).get('token');

      // Check if token is valid
      if (token) {
        stytchClient.oauth.authenticate(token, {
          session_duration_minutes: 60,
        });
      }
      else{
        // Redirect to login
        //window.location.href = "#/auth/sign-in/login";
      }
    }
  }, [stytchClient, session]);


  const [tableData, setTableData] = React.useState([]);

  useEffect(() => {
    const api_url = "/ape/api/v1/project/projects/" + stytchUser.user.user_id;
    fetch(api_url).then((response) => {
      response.json().then((data) => {
        setTableData(data);
        console.log(data);
      });
    });
  }, []);

  return (
    <Flex direction='column' maxH="100vh">
      <Flex>
        <SafariProjectsTable
          columnsData={tableColumnsManagement}
          tableData={tableData}
          setTableData={setTableData}
          map={map}
        />
      </Flex>
      <Flex maxH="100vh">
        <ProjectsMapView map={map} setMap={setMap} tableData={tableData} />
      </Flex>
    </Flex>
  );
}
