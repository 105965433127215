import React from "react";

import Card from "components/card/Card";
import {
  Flex,
  Grid,
  useColorModeValue,
  IconButton,
  Heading,
} from "@chakra-ui/react";
import { useStytchUser, useStytchSession } from "@stytch/react";
import { motion } from "framer-motion";
import { useDisclosure } from "@chakra-ui/react";
import { MdArrowLeft } from "react-icons/md";

import MapComponent from "./components/MapComponent";
import BuildingTable from "./components/BuildingTable";
import { tableBuildingColumns } from "views/app/projects/variables/tableBuildingSelected";
import MetricsContainer from "./components/objects/MetricsContainer.js";
import { metricsComponents } from "./variables/metrics";
import ResultsTab from "./components/ResultsTab";

export default function Project({ match }) {
  const session = useStytchSession();
  const stytchUser = useStytchUser();
  const { isOpen, onToggle } = useDisclosure();
  const [hidden, setHidden] = React.useState(!isOpen);

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandText = useColorModeValue("brand.500", "white");
  const cardBg = useColorModeValue("secondaryGray.700", "secondaryGray.700");

  const [formInputs, setFormInputs] = React.useState({});
  const [projectDefaultParams, setProjectDefaultParams] = React.useState({});

  const [metricsContainer, setMetricsContainer] = React.useState(
    new MetricsContainer()
  );
  const [map, setMap] = React.useState(
    React.useMemo(() => {
      return {
        center: {
          lat: 51.5,
          lng: -0.12,
        },
        zoom: 8,
        maxZoom: 35,
      };
    }, [])
  );

  React.useEffect(() => {
    if (stytchUser.user === null) {
      window.location.href = "/#/auth/sigh-in/login";
    }
  }, [stytchUser]);

  const [buildingTableData, setBuildingTableData] = React.useState([]);
  const [initialBuildingTableData, setInitialBuildingTableData] =
    React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const {
    params: { id },
  } = match;

  // fetch project data from API
  React.useEffect(() => {
    fetch(
      "/ape/api/v1/project/project/" +
        stytchUser.user.user_id +
        "/" +
        id.substring(1)
    ).then((response) => {
      response.json().then((response) => {
        setProjectDefaultParams(response.data.projectDefaults);
        const data = response.data.project;
        const replacementContainer = MetricsContainer.copyMetricsContainer(
          data.projectMetricsContainer.container
        );
        setMetricsContainer(replacementContainer);
        var tableDataFetched = [];
        for (const projectInstance of data.projectInstances) {
          tableDataFetched.push({
            buildingId: projectInstance.building.id,
            buildingPolygon: projectInstance.building,
            buildingObstructions: projectInstance.obstructions,
          });
        }
        setInitialBuildingTableData(tableDataFetched);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    });

    fetch(
      "/ape/api/v1/project/project/formInputs/" + stytchUser.user.user_id
    ).then((response) => {
      response.json().then((response) => {
        const data = response;
        setFormInputs(data);
      });
    });
  }, []);

  // Call onToggle to initally open the sidebar
  React.useEffect(() => {
    onToggle();
  }, []);
  

  return loading ? (
    <div />
  ) : (
    <Heading size="md">
      {metricsContainer.summary.projectName}
      <Flex
        flex="1"
        bg="bg-surface"
        overflowY="auto"
        height="100vh"
        py={{
          base: "6",
          sm: "8",
        }}
        px={{
          base: "4",
          sm: "6",
        }}
      >
        <Grid
          h="100%"
          w="100%"
          gridTemplateColumns={{ base: "repeat(2, 1fr)" }}
          gap="40px"
        >
          <Flex gridArea={{ base: "2 / 1 / 3 / 3" }}>
            <ResultsTab
              userId={stytchUser.user.user_id}
              projectId={id.substring(1)}
              metricsContainer={metricsContainer}
              setMetricsContainer={setMetricsContainer}
            />
          </Flex>

          <Flex  gridArea={{ base: "1 / 1 / 2 / 3" }}>
            <Card w="100%" h="1000px">
              <BuildingTable
                map={map}
                columnsData={tableBuildingColumns}
                tableData={buildingTableData}
                metricsContainer={metricsContainer}
                setMetricsContainer={setMetricsContainer}
                formInputs={formInputs}
              />
            </Card>

            <Flex align="right" onClick={onToggle} padding="10px">
              <Card
                align="right"
                onClick={onToggle}
                w="50px"
                h="1000px"
                _hover={{ bg: bgButton, color: "gray" }}
                _active={{ bg: bgButton, color: "gray" }}
                _focus={{ boxShadow: "none" }}
              >
                <IconButton
                  icon={<MdArrowLeft size="sm" />}
                  variant="ghost"
                  size={"sm"}
                  w="100%"
                  color={brandText}
                  top="50%"
                  align="left"
                />
              </Card>
            </Flex>
            <motion.div
              hidden={hidden}
              initial={true}
              onAnimationStart={() => setHidden(false)}
              onAnimationComplete={() => setHidden(!isOpen)}
              animate={{ width: isOpen ? 800 : 0 }}
              style={{
                right: "100%",
                top: "0",
              }}
            >
              <MapComponent
                map={map}
                setMap={setMap}
                metricsContianer={metricsContainer}
                setMetricsContainer={setMetricsContainer}
                buildingTableData={buildingTableData}
                setBuildingTableData={setBuildingTableData}
                projectId={id.substring(1)}
                initalBuildingTable={initialBuildingTableData}
                projectDefaultParams={projectDefaultParams}
              />
            </motion.div>
          </Flex>
        </Grid>
      </Flex>
    </Heading>
  );
}
