import {
  Box,
  Heading,
  HStack,
  Icon,
  Square,
  Button,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";

import { MdOutlineDisabledByDefault } from "react-icons/md";
import { GrCircleInformation } from "react-icons/gr";

export const Stat = (props) => {
  const { label, target, icon, unit, description, ...boxProps } = props;
  return (
    <Box
      px={{
        base: "4",
        md: "6",
      }}
      py={{
        base: "5",
        md: "6",
      }}
      bg="bg-surface"
      borderRadius="lg"
      boxShadow={useColorModeValue("lg", "lg")}
      {...boxProps}
    >
      <Tooltip hasArrow label={description}>
        <Button>
          <Icon as={GrCircleInformation} />
        </Button>
      </Tooltip>
      <Stack
        spacing={{
          base: "5",
          md: "6",
        }}
      >
        <Stack direction="row" justify="space-between">
          <HStack spacing="4">
            <Square size="12" bg="bg-accent-subtle" borderRadius="md">
              <Icon as={icon} boxSize="6" color="on-accent" />
            </Square>
            <Text fontWeight="medium">{label}</Text>
          </HStack>
        </Stack>
        <Stack direction="row" justify="space-evenly">
          <Heading
            size={useBreakpointValue({
              base: "sm",
              md: "md",
            })}
          >
            {target}
          </Heading>
          <Heading
            size={useBreakpointValue({
              base: "xsm",
            })}
          >
            {unit}
          </Heading>
        </Stack>
      </Stack>
    </Box>
  );
};
