export const tableColumnsManagement = [
  {
    Header: "  ",
    accessor: "edit",
  },
  {
    Header: "Project Name",
    accessor: "projectName",
  },
  {
    Header: "Project Client",
    accessor: "projectClient",
  },
  {
    Header: "Project ID",
    accessor: "projectId",
  },
  {
    Header: "Address",
    accessor: "projectAddress",
  },
  {
    Header: "DC Size",
    accessor: "dcSize",
  },
  {
    Header: "Specific Production",
    accessor: "specificProduction",
  },
  {
    Header: "Construction Cost $/W",
    accessor: "constructionCost$/W",
  },
  {
    Header: "Contract Price $/W",
    accessor: "contractPrice$/W",
  },
  {
    Header: "IRR",
    accessor: "irr",
  },
  {
    Header: "PPA Rate",
    accessor: "ppa",
  },
  {
    Header: "Payback Period",
    accessor: "paybackPeriod",
  },
  {
    Header: "Carbon Offset (tons/yr)",
    accessor: "carbonOffset",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: " ",
    accessor: "button",
  },
];