
import React from 'react';
import { useForm } from 'react-hook-form';

import { Divider, Button, Input, Heading, NumberInput, NumberInputField, FormControl, FormLabel, FormErrorMessage, FormHelperText, Select, Flex } from '@chakra-ui/react';

import MetricsContainer from './objects/MetricsContainer';
export default function InstanceMetricsParametersForm(props) {

    const { formInputs, tableData, setTableData, metricsContainer, setMetricsContainer, onOpen } = props;
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm()

    const buildingId = tableData.buildingId;

    const [projectType, setProjectType] = React.useState(metricsContainer.engineering.buildings[buildingId].selectables.projectType)

    // Get building id
    console.log(metricsContainer)

    const onProjectTypeChange = (event) => {
        const value = event.target.value;
        setProjectType(value);
        // Overide building table name and project type
    };

    function onSubmit(values) {
        const instanceParameters = values;
        setTableData.find((building) => building.buildingId === tableData.buildingId).buildingName = instanceParameters.instanceName;
        setTableData.find((building) => building.buildingId === tableData.buildingId).projectType = instanceParameters.instanceType;

        // Update metrics container
        const metricsContainerCopy = MetricsContainer.copyMetricsContainer(metricsContainer);
        metricsContainerCopy.engineering.buildings[buildingId].selectables.projectType = instanceParameters.instanceType;
        metricsContainerCopy.engineering.buildings[buildingId].inputs.buildingName = instanceParameters.instanceName;
        metricsContainerCopy.engineering.buildings[buildingId].selectables.rackingModule = instanceParameters.rackingType;
        metricsContainerCopy.engineering.buildings[buildingId].selectables.solarModule = instanceParameters.solarModule;
        metricsContainerCopy.engineering.buildings[buildingId].selectables.inverterManufacturer = instanceParameters.inverterManufacturer;

        metricsContainerCopy.cost.buildings[buildingId].selectables.electricalFeed = instanceParameters.electricalFeed;
        metricsContainerCopy.cost.buildings[buildingId].selectables.laborType = instanceParameters.laborType;
        metricsContainerCopy.cost.buildings[buildingId].selectables.taxType = instanceParameters.taxType;
        metricsContainerCopy.cost.buildings[buildingId].selectables.taxRate = instanceParameters.taxRate;
        metricsContainerCopy.cost.buildings[buildingId].selectables.addedExtraCost = instanceParameters.addedExtraCost;
        metricsContainerCopy.cost.buildings[buildingId].selectables.numMeters = instanceParameters.numMetersOnSite;
        metricsContainerCopy.cost.buildings[buildingId].selectables.projectMargin = instanceParameters.projectTargetMargin;
        metricsContainerCopy.cost.buildings[buildingId].selectables.commodityAdjustment = instanceParameters.commodityAdjustment;
        metricsContainerCopy.cost.buildings[buildingId].selectables.moduleSpotCost = instanceParameters.moduleSpotCost;
        metricsContainerCopy.cost.buildings[buildingId].selectables.invCost = instanceParameters.invCost;
        metricsContainerCopy.cost.buildings[buildingId].selectables.optCost = instanceParameters.optCost;

        metricsContainerCopy.financial.buildings[buildingId].selectables.systemOwner = instanceParameters.systemOwner;
        metricsContainerCopy.financial.buildings[buildingId].selectables.projectTerm = instanceParameters.projectTerm;
        metricsContainerCopy.financial.buildings[buildingId].selectables.estimatedCod = instanceParameters.estimatedCod;
        metricsContainerCopy.financial.buildings[buildingId].selectables.estimatedMob = instanceParameters.estimatedMob;
        metricsContainerCopy.financial.buildings[buildingId].selectables.modType = instanceParameters.modType;
        metricsContainerCopy.financial.buildings[buildingId].selectables.safariDeveloped = instanceParameters.safariDeveloped === "Yes" ? true : false;
        metricsContainerCopy.financial.buildings[buildingId].selectables.safariConstructed = instanceParameters.safariConstructed === "Yes" ? true : false;
        metricsContainerCopy.financial.buildings[buildingId].selectables.ppaOfftakerCredit = instanceParameters.ppaOfftakerCredit;
        metricsContainerCopy.financial.buildings[buildingId].selectables.degredation = instanceParameters.degredation;
        metricsContainerCopy.financial.buildings[buildingId].selectables.eRateKwH = instanceParameters.eRateKwH;
        metricsContainerCopy.financial.buildings[buildingId].selectables.sector = instanceParameters.sector;
        metricsContainerCopy.financial.buildings[buildingId].selectables.pbiRate = instanceParameters.pbiRate;
        metricsContainerCopy.financial.buildings[buildingId].selectables.pbiEscalation = instanceParameters.pbiEscalation;
        metricsContainerCopy.financial.buildings[buildingId].selectables.pbiTerm = instanceParameters.pbiTerm;
        metricsContainerCopy.financial.buildings[buildingId].selectables.noOm = instanceParameters.noOm === "Yes" ? true : false;
        metricsContainerCopy.financial.buildings[buildingId].selectables.slp = instanceParameters.slp;
        metricsContainerCopy.financial.buildings[buildingId].selectables.slpEscalator = instanceParameters.slpEscalator;
        metricsContainerCopy.financial.buildings[buildingId].selectables.pilotOverride = instanceParameters.pilotOverride;
        metricsContainerCopy.financial.buildings[buildingId].selectables.pilotOverrideMw = instanceParameters.pilotOverrideMw;
        setMetricsContainer(metricsContainerCopy);
        
        console.log(onOpen)
        onOpen();
    }

    
    return (

        <Flex padding="10px">
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl id="parameterForm" isRequired>
                    <Heading size='md'>Instance Parameters</Heading>

                    <FormLabel>Instance Name</FormLabel>
                    <Input {...register("instanceName", { required: true })} defaultValue={tableData.buildingName} />
                    <FormHelperText>Name of the project instance.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Instance Type</FormLabel>
                    <Select id="instanceType" {...register("instanceType", { required: true })} onChange={onProjectTypeChange} defaultValue={metricsContainer.engineering.buildings[buildingId].selectables.projectType}>
                    {
                            formInputs.engineering.projectTypes.map(projectType => {
                                return <option value={projectType.projectTypeName}>{projectType.projectTypeName}</option>
                            })
                        }
                    </Select>
                    <FormHelperText>Project Instance Type.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <Divider orientation='horizontal' />

                    <Heading size='md'>Engineering Parameters</Heading>

                    <FormLabel>Racking Type</FormLabel>
                    <Select {...register("rackingType", { required: true })} defaultValue={metricsContainer.engineering.buildings[buildingId].selectables.rackingType}>
                        {
                            formInputs.engineering.rackingModules.filter(rackingModule => rackingModule.projectType === projectType).map(rackingModule => {
                                return <option value={rackingModule.moduleName}>{rackingModule.moduleName}</option>
                            })
                        }
                    </Select>

                    <FormHelperText>Racking Module for Instance Type.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Solar Module Selection</FormLabel>
                    <Select {...register("solarModule", { required: true })} defaultValue={metricsContainer.engineering.buildings[buildingId].selectables.solarModule}>
                        {
                            formInputs.engineering.solarModules.map(solarModule => {
                                return <option value={solarModule.moduleName}>{solarModule.moduleName}</option>
                            })
                        }
                    </Select>

                    <FormHelperText>Solar Module for Instance Type.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Inverter Manufacturer Selection</FormLabel>
                    <Select {...register("inverterManufacturer", { required: true })} defaultValue={metricsContainer.engineering.buildings[buildingId].selectables.inverterManufacturer}>
                        {
                            formInputs.engineering.inverterManufacturers.map(inverterManufacturer => {
                                return <option value={inverterManufacturer.manufacturerName}>{inverterManufacturer.manufacturerName}</option>
                            })
                        }
                    </Select>

                    <FormHelperText>Inverter Manufacturer for Instance Type.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <Divider orientation='horizontal' />

                    <Heading size='md'>Cost/Construction Parameters</Heading>

                    <FormLabel>Electrical Feed</FormLabel>
                    <Select {...register("electricalFeed", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.electricalFeed}>
                        {
                            formInputs.cost.electricalFeeders.map(electricalFeeder => {
                                return <option value={electricalFeeder.electricalFeedName}>{electricalFeeder.electricalFeedName}</option>
                            })
                        }
                    </Select>

                    <FormHelperText>Type of electrical feed used for construction.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Labor Type</FormLabel>
                    <Select {...register("laborType", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.laborType}>
                        {
                            formInputs.cost.laborTypes.map(laborType => {
                                return <option value={laborType.laborTypeName}>{laborType.laborTypeName}</option>
                            })
                        }
                    </Select>

                    <FormHelperText>Type of labor used for construction.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Tax Type</FormLabel>
                    <Select {...register("taxType", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.taxType}>
                        {
                            formInputs.cost.taxTypes.map(taxType => {
                                return <option value={taxType.taxTypeName}>{taxType.taxTypeName}</option>
                            })
                        }
                    </Select>

                    <FormHelperText>Tax type for the construction.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Tax Rate [$]</FormLabel>

                    <NumberInput max={100.0} min={0.0} {...register("taxRate", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.taxRate}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>Tax type for the construction.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Added Extra Cost [$]</FormLabel>

                    <NumberInput min={0.0} {...register("addedExtraCost", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.addedExtraCost}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>Tax type for the construction.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Number of Meters On Site [m]</FormLabel>

                    <NumberInput min={0.0} {...register("numMetersOnSite", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.numMeters}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>Tax type for the construction.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Project Target Margin</FormLabel>

                    <NumberInput min={0.0} {...register("projectTargetMargin", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.projectMargin}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>Tax type for the construction.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Commodity Adjustment</FormLabel>

                    <NumberInput min={0.0} max={1.0} {...register("commodityAdjustment", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.commodityAdjustment}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>Commodity Adjustment.</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Module Spot Cost</FormLabel>

                    <NumberInput min={0.0} max={1.0} {...register("moduleSpotCost", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.moduleSpotCost}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>Module Spot Cost</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Inverter Cost</FormLabel>

                    <NumberInput min={0.0} {...register("invCost", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.invCost}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>Inverter Cost</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Opt Cost</FormLabel>

                    <NumberInput min={0.0} {...register("optCost", { required: true })} defaultValue={metricsContainer.cost.buildings[buildingId].selectables.optCost}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>Opt Cost</FormHelperText>
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <Heading size='md'>Finance Parameters</Heading>
                    
                    <FormLabel>System Owner</FormLabel>

                    <Select {...register("systemOwner", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.systemOwner}>
                    {
                            formInputs.financial.projectOwnerTypes.map(projectOwner => {
                                return <option value={projectOwner.projectOwnerName}>{projectOwner.projectOwnerName}</option>
                            })
                        }
                    </Select>
                    
                    <FormErrorMessage>
                        {errors.email && "Email is required"}
                    </FormErrorMessage>

                    <FormLabel>Project Term</FormLabel>

                    <NumberInput min={0.0} {...register("projectTerm", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.projectTerm}>
                        <NumberInputField />
                    </NumberInput>

                    <FormHelperText>Length of the project in Years</FormHelperText>

                    <FormLabel>Estimated COD</FormLabel>

                    <NumberInput min={new Date().getFullYear()} {...register("estimatedCod", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.estimatedCod}>
                        <NumberInputField />
                    </NumberInput>

                    <FormHelperText>Year of estimated COD</FormHelperText>

                    <FormLabel>Estimated MOB</FormLabel>

                    <NumberInput min={new Date().getFullYear()} {...register("estimatedMob", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.estimatedMob}>
                        <NumberInputField />
                    </NumberInput>

                    <FormHelperText>Year of estimated PTO</FormHelperText>

                    <FormLabel>MOD Type</FormLabel>

                    <Select 
                        {...register("modType", { required: true })}
                        defaultValue={metricsContainer.financial.buildings[buildingId].selectables.modType}
                    >
                        <option value="Monofacial">Monofacial</option>
                        <option value="Bifacial">Bifacial</option>
                    </Select>
                
                    <FormHelperText>Type of Module (monofacial or bifacial)</FormHelperText>

                    <FormLabel>Safari Developed</FormLabel>

                    <Select
                        {...register("safariDeveloped", { required: true })}
                        defaultValue={metricsContainer.financial.buildings[buildingId].selectables.safariDeveloped}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Select>

                    <FormLabel>Safari Constructed</FormLabel>

                    <Select
                        {...register("safariConstructed", { required: true })}
                        defaultValue={metricsContainer.financial.buildings[buildingId].selectables.safariConstructed}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Select>

                    <FormLabel>PPA Offtaker Credit</FormLabel>

                    <Select
                        {...register("ppaOfftakerCredit", { required: true })}
                        defaultValue={metricsContainer.financial.buildings[buildingId].selectables.ppaOfftakerCredit}
                    >
                        <option value="High IG">High IG</option>
                        <option value="Low IG">Low IG</option>
                        <option value="Sub IG">Sub IG</option>
                    </Select>

                    <FormLabel>Degradation</FormLabel>

                    <NumberInput min={0.0} max={1.0} {...register("degredation", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.degredation}>
                        <NumberInputField />
                    </NumberInput>

                    <FormHelperText>Percentage of degredation, yearly</FormHelperText>

                    <FormLabel>Energy Rate</FormLabel>

                    <NumberInput min={0.0} max={1.0} {...register("eRateKwH", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.eRateKwH}>
                        <NumberInputField />
                    </NumberInput>

                    <FormHelperText>Energy Rate in KwH</FormHelperText>

                    <FormLabel>Sector</FormLabel>

                    <Select
                        {...register("sector", { required: true })}
                        defaultValue={metricsContainer.financial.buildings[buildingId].selectables.sector}
                    >
                        <option value="Commercial">Commercial</option>
                        <option value="Industrial">Industrial</option>
                    </Select>

                    <FormLabel>PBI Rate</FormLabel>

                    <NumberInput min={0.0} max={1.0} {...register("pbiRate", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.pbiRate}>
                        <NumberInputField />
                    </NumberInput>

                    <FormHelperText>PBI Linear Incentive Rate</FormHelperText>

                    <FormLabel>Percent Escalation</FormLabel>

                    <NumberInput min={0.0} max={1.0} {...register("pbiEscalation", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.pbiEscalation}>
                        <NumberInputField />
                    </NumberInput>

                    <FormHelperText>Percent escalation per year for PBI Linear Incentive Rate</FormHelperText>

                    <FormLabel>PBI Term</FormLabel>

                    <NumberInput min={0.0} {...register("pbiTerm", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.pbiTerm}>
                        <NumberInputField />
                    </NumberInput>

                    <FormHelperText>Year of PBI rate</FormHelperText>

                    <FormLabel>No OM</FormLabel>

                    <Select
                        {...register("noOm", { required: true })}
                        defaultValue={metricsContainer.financial.buildings[buildingId].selectables.noOm}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Select>

                    <FormLabel>Site Lease Payment ($/yr)</FormLabel>

                    <NumberInput min={0.0} {...register("slp", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.slp}>
                        <NumberInputField />
                    </NumberInput>

                    <FormLabel>Site Lease Payment Escalator</FormLabel>

                    <NumberInput min={0.0} {...register("slpEscalator", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.slpEscalator}>
                        <NumberInputField />
                    </NumberInput>

                    <FormLabel>Pilot Override</FormLabel>

                    <Select
                        {...register("pilotOverride", { required: true })}
                        defaultValue={metricsContainer.financial.buildings[buildingId].selectables.pilotOverride}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Select>

                    <FormLabel>PILOT override $/MW</FormLabel>

                    <NumberInput min={0.0} {...register("pilotOverrideMw", { required: true })} defaultValue={metricsContainer.financial.buildings[buildingId].selectables.pilotOverrideMw}>
                        <NumberInputField />
                    </NumberInput>

                </FormControl>

                <Button mt={4} colorScheme='teal' isLoading={isSubmitting} type='submit'>
                    Submit
                </Button>

            </form>
        </Flex>


    );
}