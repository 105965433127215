import {StytchLogin} from '@stytch/react';
import { Stytch, SDKProductTypes, OAuthProvidersTypes, OneTapPositions } from '@stytch/stytch-react';

import { Products, OAuthProviders } from '@stytch/vanilla-js';


export default function MicrosoftOAuth() {

  const loginOrSignupViewConfig = {
    oauthOptions: {
      providers: [
        {
          type: OAuthProvidersTypes.Microsoft,
          custom_scopes: ['https://graph.microsoft.com/calendars.read'],
        },
        {
          type: OAuthProvidersTypes.Slack,
        },
        {
          type: OAuthProvidersTypes.Twitch,
        },
      ],
    },
    products: [
      SDKProductTypes.oauth,
    ],
  };

  const microsoftOAuthConfig = {
    oauthOptions: {
      providers: [
        {
          type: OAuthProviders.Microsoft
          ,
        },
      ],
    },
    products: [Products.oauth],
  };

  return (
    <div align="center">
      <StytchLogin
        config={microsoftOAuthConfig}
      />
    </div>
  );
}
