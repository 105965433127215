import MetricsContainer from "../objects/MetricsContainer";

export function buildingBuildingObstructionDetectorTools(
  e,
  map,
  selectedBuildings,
  projectId,
  userId,
  draw,
  metricsContainer,
  setMetricsContainer,
  setBuildingTableData,
  projectDefaultParams
) {
  // Get all builidings without obstructions
  const buildingsToProcess = Object.values(selectedBuildings).filter(
    (building) => building.obstructions.length === 0
  );

  var inferenceFormatBuildings = [];

  buildingsToProcess.forEach((building) => {
    building.generatePixelCoordinates();
    console.log(building.toInferenceFormat());
    inferenceFormatBuildings.push(building.toInferenceFormat());
  });

  fetch("/ape/api/v1/project/inference/" + userId + "/" + projectId, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify({
      buildings: inferenceFormatBuildings,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      buildingsToProcess.forEach((building) => {
        building.addManyObstructions(data[building.buildingId], draw);
      });

      const metricsContainerCopy =
        MetricsContainer.copyMetricsContainer(metricsContainer);

      Object.values(selectedBuildings).map((building) => {
        metricsContainerCopy.addBuilding(
          building.toMetricsFormat(metricsContainer),
          projectDefaultParams
        );
      });

      setMetricsContainer(metricsContainerCopy);

      // Update the buildingTableData with all selected buildings
      const tableData = Object.values(selectedBuildings).map((building) => {
        return building.toTableFormat(metricsContainer);
      });
      setBuildingTableData(tableData);
    });
}
